export default {
  state: {
    highlightMode: false,
  },
  getters: {
    getHighlightMode(state) {
      return state.highlightMode
    },
  },
  mutations: {
    SET_HIGHLIGHT_MODE(state, val) {
      state.highlightMode = val
    },
  },
  actions: {
    setHighlightMode({ commit }, val) {
      commit('SET_HIGHLIGHT_MODE', val)
    },
  },
  modules: {},
}
