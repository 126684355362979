export default {
  state: {
    solvedToday: false,
    statistics: undefined,
  },
  getters: {
    getSolvedToday(state) {
      return state.solvedToday
    },
  },
  mutations: {
    SET_STATISTICS(state, payload) {
      state.statistics = payload
    },
    SET_SOLVED_TODAY(state, payload) {
      state.solvedToday = payload
    },
  },
  actions: {
    setStatistics({ commit }, payload) {
      commit('SET_STATISTICS', payload.statistics)
      commit('SET_SOLVED_TODAY', payload.solvedToday)
    },
  },
  modules: {},
}
