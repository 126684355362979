const copyright = {
  name: 'Marco Volberg',
  company: 'Internet Services & Website Marketing',
  foundingYear: '2006',
  images: [
    {
      filename: '291288415_thumbnail.jpg',
      copyrightOwner: 'Wirestock - stock.adobe.com',
    },
    {
      filename: '50961283_thumbnail.jpg',
      copyrightOwner: 'Iakov Kalinin - stock.adobe.com',
    },
    {
      filename: '84628947_thumbnail.jpg',
      copyrightOwner: 'Smileus - stock.adobe.com',
    },
    {
      filename: '97752724_thumbnail.jpg',
      copyrightOwner: 'Fokussiert - stock.adobe.com',
    },
    {
      filename: '99129958_thumbnail.jpg',
      copyrightOwner: 'SusaZoom - stock.adobe.com',
    },
    {
      filename: '94105540_thumbnail.jpg',
      copyrightOwner: 'Vitaly Krivosheev - stock.adobe.com',
    },
    {
      filename: 'happy_birthday_thumbnail.jpg',
      copyrightOwner: 'Good Studio - stock.adobe.com',
    },
  ],
}

export { copyright }
