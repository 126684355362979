import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import faIcon from './plugins/fontawesome-pro'
import i18n from './i18n'
import vuecookies from './plugins/vuecookies'
import vueDomPurifyHtml from './plugins/vue-dom-purify-html'

import vuescreensize from './plugins/vue-screen-size'
import vueyoutubeembed from './plugins/vue-youtube-embed'
import { vuetify } from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuecookies,
  vueDomPurifyHtml,
  vuescreensize,
  vueyoutubeembed,
  vuetify,
  faIcon,
  i18n,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  render: (h) => h(App),
}).$mount('#app')
