<template>
  <svg
    :width="width"
    viewBox="0 0 165 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
  >
    <path d="M69.008,16.456l4.725,0.303l0.104,-9.947" />
    <path d="M161.186,37.67l-158.351,1.323" />
    <path d="M161.468,35.726l-158.585,-1.72" />
    <path
      d="M2.999,20.245l7.612,0.982l0.107,-0.94l3.88,0.637l-0.348,-2.21l1.102,-0.915l1.181,-6.143l1.083,6.266l1.145,0.896l-0.325,3.205l3.373,0.122l0.245,-0.759l0.636,0.881l2.185,0.083l0.514,-0.918l1.695,0.37l0.591,0.591l1.347,0.119l2.233,-0.146l0.065,0.719l4.881,-0.083l0.101,-16.919l2.169,-0.205l0.062,-1.962l26.009,-0.673l2.837,0.9l-0.006,1.107l1.393,0.022l5.028,0.801l0.074,1.405l12.565,0l2.752,0.429l-0.061,1.187l1.823,-0.165l4.003,0.737l-0.061,0.698l8.977,-0.104l-0.021,-0.863l2.733,0.471l1.043,1.126l1.717,0.144l2.984,0.407l0.165,9.06l-5.714,0.22l-0.083,4.004l1.246,0.018l2.5,0.37l1.45,0.098l2.577,0.101l2.598,0.144l0.144,0.676l3.204,0.471l0.225,2.095l1.092,0.122l0.061,-1.187l1.185,1.059l4.174,-0.062l-0.166,-0.798l-0.247,-0.841l2.475,-0.062l0.496,-0.829l0.162,-4.554l0.428,-1.427l0.224,-5.068l0.594,-2.707l0.614,1.328l1.344,-10.941l1.286,9.453l1.08,-9.144l1.69,10.528l0.346,-1.371l0.945,6.288l-0.061,2.063l0.367,0.998l2.883,-0.123l0.205,-0.719l0.285,0.738l0.963,-0.04l0,-1.518l0.591,1.347l1.701,0.061l0.184,-3.045l0.631,-5.591l0.921,5.591l0,3.024l2.108,0.058l0.165,-0.905l0.511,0.875l3.884,0.083l1.138,1.861l0.413,-0.716l0.349,1.719l0.676,0.511l0.535,-0.697l0.45,0.492l0.799,0.799l0.263,3.149l0.82,0.78l1.988,0.018"
    />
    <path
      d="M36.137,22.964l3.311,0.425l1.456,-0.471l1.129,0.205l0.921,1.451l0.123,-1.246l1.248,0.061l1.965,0.04l1.101,1.512l-0.407,-1.169l1.249,0l1.453,0.003l1.959,0.184l1.879,0.04l0.123,1.514l0.793,-1.061l5.245,0.223"
    />
    <path d="M68.843,5.355l0.165,11.101" />
    <path d="M46.421,22.979l0.203,-6.513l22.454,0.018" />
    <path d="M55.064,16.759l0.076,7.517" />
    <path d="M59.685,16.759l0.097,17.606" />
    <path d="M70.94,18.856l0.042,5.038l6.467,0.489l0.328,1.347l0.676,0.389l1.288,0.183l0.205,8.019" />
    <path d="M65.271,34.389l-0.083,-17.732" />
    <path d="M14.34,34.06l0.144,-9.699l9.637,0l0.186,1.671l0.643,0.42l0.044,3.441l-0.91,1.564l-0.245,1.864" />
    <path d="M24.235,24.361l29.605,1.593l0.098,8.352" />
    <path d="M34.767,24.889l-0.06,2.095l0.92,0.383l-0.014,2.542l-0.642,2.156l-0.145,2.127" />
    <path d="M71.2,18.727l19.851,-0.003l0.065,-9.877" />
    <path d="M91.116,18.856l3.668,0.073l0.036,-8.947" />
    <path d="M107.85,20.762l-16.652,-0.091" />
    <path d="M109.446,11.47l0.019,9.254l-0.019,-9.254Z" />
    <path d="M94.82,20.724l0,7.96l5.91,0.016l1.829,1.894l0,0.148l-0.142,-9.767" />
    <path d="M76.959,18.668l0.097,5.565" />
    <path d="M82.594,18.856l0.162,15.584" />
    <path d="M91.116,20.656l0.109,7.765l4.853,0.367" />
    <path d="M90.166,34.813l0.018,-5.404l0.831,-0.716l-2.886,0.062l-1.616,0.409l0.072,5.537" />
    <path d="M120.765,29.402l0.018,5.464" />
    <path d="M121.05,30.972l40.32,-0.285l0.098,1.807l-40.443,0.38" />
    <path
      d="M129.807,23.418l1.041,-0.324l0.594,-1.901l0.204,2.427l1.922,0l0.637,1.925l0.817,0.572l1.373,0.367l0,1.182l0.961,0.328l0.9,-0.248l1.8,-0.083l0.633,0.49l4.695,0.045l0.939,0.083l2.127,0.061l1.695,0.062l1.108,0.55l1.43,-0.039l1.554,0.061l1.371,0.425l2.27,0l1.499,0.144"
    />
    <path d="M71.184,24.06l-5.718,0.068" />
    <path d="M68.315,24.486l0.134,9.803" />
    <path d="M106.637,24.873l-4.078,0.583" />
    <path d="M86.513,29.402l0.062,-10.499" />
    <path d="M99.293,20.728l0.066,7.682" />
    <path d="M102.559,30.742l0,4.143" />
    <path d="M108.686,25.102l0.064,9.783" />
    <path d="M117.348,26.628l-0.015,8.257" />
  </svg>
</template>

<script>
export default {
  name: 'CologneSkyline',
  data: () => ({
    width: '248px',
  }),
}
</script>

<style lang="scss" scoped>
svg {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
  fill: none;
  stroke-width: 1px;
}
#app.theme--dark svg {
  stroke: map-get($grey, 'lighten-1');
}
#app.theme--light svg {
  stroke: map-get($blue-grey, 'base');
}
</style>
