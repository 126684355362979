<template>
  <div class="navbar hidden-sm-and-down">
    <v-row class="navbar__row mb-0 mt-8">
      <v-col
        v-for="(item, index) in items"
        :key="index"
        class="navbar__col px-0 py-2 py-lg-3 text-center"
        :class="[`design-color${index}`, index === navigation.activeElementId ? 'active' : 'inactive']"
      >
        <a class="navbar__link" :href="item.href">
          <strong>{{ item.title }}</strong>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { navbar } from '@/config/index.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters(['navigation']),
    items() {
      return navbar.items
    },
  },
}
</script>

<style lang="scss">
.navbar {
  &__link {
    text-decoration: none;
  }
  .active {
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    a {
      font-size: 13px;
    }
    .active {
      background-size: 12px 6px;
    }
  }
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    a {
      font-size: 16px;
    }
    .active {
      background-size: 16px 8px;
    }
  }
}

#app.theme--light .active {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><path d="M8,0L0,8L16,8L8,0" style="fill:rgb(255,255,255);stroke:rgb(255,255,255);stroke-width:1px;"/></svg>');
}
#app.theme--dark .active {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><path d="M8,0L0,8L16,8L8,0" style="fill:rgb(0,0,0);stroke:rgb(0,0,0);stroke-width:1px;"/></svg>');
}

@for $index from 0 through 5 {
  #app.theme--light {
    .navbar {
      &__row {
        background-color: nth($colors-light, 6);
        border-bottom: 5px solid white;
      }
    }
    .design-color#{$index}.inactive {
      background-color: nth($colors-light, $index + 1);
    }
    .design-color#{$index}.active {
      background-color: nth($colors-dark, $index + 1);
    }
    .design-color#{$index}.inactive a {
      color: nth($colors-dark, $index + 1) !important;
    }
    .design-color#{$index}.active a {
      color: nth($colors-light, $index + 1) !important;
    }
  }
  #app.theme--dark {
    .navbar {
      &__row {
        background-color: nth($colors-dark, 6);
        border-bottom: 5px solid black;
      }
    }
    .design-color#{$index}.inactive {
      background-color: nth($colors-dark, $index + 1);
    }
    .design-color#{$index}.active {
      background-color: nth($colors-light, $index + 1);
    }
    .design-color#{$index}.inactive a {
      color: nth($colors-light, $index + 1) !important;
    }
    .design-color#{$index}.active a {
      color: nth($colors-dark, $index + 1) !important;
    }
  }
}
</style>
