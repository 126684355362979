const responsive = {
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },
    orientation() {
      if (this.$vuetify.breakpoint.height > this.$vuetify.breakpoint.width) {
        return 'portrait'
      }
      return 'landscape'
    },
  },
}

export { responsive }
