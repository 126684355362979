<template>
  <div v-if="isBlackFriday()">
    <v-divider class="my-3"></v-divider>
    <div v-if="size === 'small'" class="black-friday-small">
      <Hyperlink :href="$t('hyperlinks.supporter.href')" :aria-label="$t('hyperlinks.supporter.purpose')" :text-decoration="false">
        <v-row class="py-1 mt-2 red accent-4 white--text text-center mx-0">
          <v-col>
            <p class="text-h5 font-weight-black mb-0">Sudoku Knacker</p>
          </v-col>
        </v-row>
        <v-row class="red accent-4 mx-0 mt-1">
          <v-col cols="1"></v-col>
          <v-col cols="5" class="white text-center black--text pa-0 py-4 black-friday-logo font-weight-black">BLACK</v-col>
          <v-col cols="5" class="black text-center white--text pa-0 py-4 black-friday-logo font-weight-black">FRIDAY</v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row class="mb-4 py-1 red accent-4 white--text text-center mx-0">
          <v-col>
            <p class="text-h6 font-weight-black mb-0">{{ $t('views.supporter.p_black_friday_1') }}</p>
            <p class="text-h6 font-weight-black mb-0">{{ $t('views.supporter.p_black_friday_2') }}</p>
          </v-col>
        </v-row>
      </Hyperlink>
    </div>
    <div v-else class="black-friday-large">
      <v-row class="pt-6 pt-sm-10 pt-md-7 pt-lg-10 red accent-4">
        <v-col cols="1"></v-col>
        <v-col cols="5" class="white text-center pa-0 black-friday-logo font-weight-black">BLACK</v-col>
        <v-col cols="5" class="black text-center white--text pa-0 black-friday-logo font-weight-black">FRIDAY</v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row class="mb-4 py-1 red accent-4 white--text text-center">
        <v-col>
          <p class="text-h5 font-weight-black mb-0">{{ $t('views.supporter.p_black_friday_1') }}</p>
          <p class="text-h5 font-weight-black mb-0">{{ $t('views.supporter.p_black_friday_2') }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { blackFriday } from '@/mixins'
import Hyperlink from '@/components/Hyperlink'

export default {
  name: 'BlackFridayAd',
  components: {
    Hyperlink,
  },
  mixins: [blackFriday],
  props: {
    size: {
      type: String,
      default: () => {
        return 'small'
      },
    },
  },
}
</script>

<style lang="scss">
.black-friday-small .black-friday-logo {
  font-size: 1.5rem;
  @media (min-width: 413px) {
    font-size: 2rem;
  }
}

.black-friday-large .black-friday-logo {
  font-size: 2.5rem;
  @media (min-width: 413px) {
    font-size: 3rem;
  }
}
</style>
