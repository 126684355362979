<template>
  <div class="adslot" :class="[theme.isDark ? 'grey darken-4' : 'grey lighten-4', adsClass]">
    <template v-if="advertiser.name === 'adsense'">
      <!-- Adsense AdSlot -->
      <ins
        class="adsbygoogle"
        :data-ad-client="advertiser.adClient"
        :data-ad-slot="advertiser.adUnits[adUnit].adSlot"
        :data-ad-format="advertiser.adUnits[adUnit].adFormat"
        :style="adStyle"
        data-full-width-responsive="true"
      ></ins>
    </template>
    <template v-else-if="advertiser.name === 'ezoic'">
      <!-- Ezoic AdSlot -->
      <div :id="`ezoic-pub-ad-placeholder-${advertiser.adUnits[adUnit].adSlot}`"></div>
    </template>
  </div>
</template>

<script>
import { adsense, ezoic } from '@/config/index.js'
import { theme } from '@/mixins'
import { mapGetters, mapActions } from 'vuex'
import { Com } from '@/util'

export default {
  name: 'Adslot',
  mixins: [theme],
  props: {
    adUnit: {
      type: String,
      required: true,
    },
    adStyle: {
      type: Object,
      default: () => {
        return { display: 'block' }
      },
    },
    adClass: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      googleInit: null,
      advertiser: undefined,
    }
  },
  computed: {
    ...mapGetters(['getAdSlots']),
    adsense() {
      return adsense
    },
    ezoic() {
      return ezoic
    },
    adsClass() {
      return this.adClass
    },
    theme() {
      return theme
    },
  },
  beforeMount() {
    // Entscheidung, welche Werbung ausgespielt werden soll
    const advertisers = [this.adsense, this.ezoic] // Fügen Sie weitere Werbepartner hinzu, wenn nötig
    this.advertiser = this.selectRandomAdvertiser(advertisers)
  },
  mounted() {
    this.pushAd()
  },
  destroyed() {
    if (this.googleInit) clearTimeout(this.googleInit)
  },
  methods: {
    ...mapActions(['addAdSlot']),
    /**
     * Überprüft zunächst, ob der Benutzer ein Unterstützer ist, um festzustellen, ob Werbung angezeigt werden soll.
     * Wenn der Benutzer kein Unterstützer ist, wird abhängig vom Werbetreibenden entweder ein Google Adsense- oder Ezoic-Werbeslot initialisiert.
     */
    async pushAd() {
      /**
       * Definiert eine Variable, um zu überprüfen, ob der aktuelle Benutzer ein Unterstützer ist.
       */
      let supporter = false

      /**
       * Überprüft, ob ein Authentifizierungstoken vorhanden ist, was darauf hindeutet, dass der Benutzer angemeldet ist.
       */
      if (this.$cookies.isKey('authenticationToken')) {
        /**
         * Initialisiert eine Instanz von Com und ruft das Benutzerprofil ab.
         */
        const com = new Com()
        const profile = await com.getProfile()

        /**
         * Setzt das aktuelle Datum auf Mitternacht, um es mit dem Unterstützer-Enddatum zu vergleichen.
         */
        let now = new Date()
        now.setHours(0, 0, 0, 0)

        /**
         * Konvertiert das Unterstützer-Enddatum aus dem Benutzerprofil in ein Datum und setzt die Zeit auf das Ende des Tages.
         */
        let userSupporter = new Date(profile.data.userSupporter)
        userSupporter.setHours(23, 59, 59)

        /**
         * Überprüft, ob das Unterstützer-Enddatum gültig und in der Zukunft liegt.
         */
        supporter = userSupporter.getTime() !== null && userSupporter.getTime() > now.getTime()
      }

      /**
       * Wenn der Benutzer kein Unterstützer ist, wird die Werbung initialisiert.
       */
      if (!supporter) {
        /**
         * Standardtimeout für die Initialisierung der Werbung.
         */
        let timeout = 200

        /**
         * Überschreibt das Standardtimeout, wenn ein benutzerdefinierter Timeout-Wert gesetzt wurde.
         */
        if (this.timeout) timeout = this.timeout

        /**
         * Initialisiert Google Adsense-Werbung, wenn der Werbetreibende 'adsense' ist.
         */
        if (this.advertiser.name === 'adsense') {
          this.googleInit = setTimeout(() => {
            if (typeof window !== 'undefined') {
              ;(window.adsbygoogle = window.adsbygoogle || []).push({})
            }
          }, timeout)
        } else if (this.advertiser.name === 'ezoic') {
          /**
           * Initialisiert Ezoic-Werbung, wenn der Werbetreibende 'ezoic' ist.
           */
          this.addAdSlot(this.ezoic.adUnits[this.adUnit].adSlot)
          window.ezstandalone = window.ezstandalone || {}
          ezstandalone.cmd = ezstandalone.cmd || []

          /**
           * Zeigt mehrere Ezoic-Werbeslots an, wenn mehr als ein Slot vorhanden ist.
           */
          if (this.getAdSlots.length > 1) {
            ezstandalone.cmd.push(() => {
              ezstandalone.displayMore(this.ezoic.adUnits[this.adUnit].adSlot)
            })
          } else {
            /**
             * Definiert und aktiviert einen einzelnen Ezoic-Werbeslot.
             */
            ezstandalone.cmd.push(() => {
              ezstandalone.define(this.ezoic.adUnits[this.adUnit].adSlot)
              ezstandalone.enable()
              ezstandalone.display()
            })
          }
        } else if (this.advertiser.name === 'dot') {
          const domain = 'sudoku-knacker.de'
          const slot = 'w2g-slot1'
          const d = document
          const w = window
          let parent = null

          // Suchen nach dem Slot-Container, falls vorhanden
          parent = document.getElementById(slot + '-cnt')

          // Event-Listener hinzufügen
          d.addEventListener(
            'wtgLoaded',
            function () {
              if (typeof w.w2g.single === 'function') {
                w.w2g.single(domain, slot, parent)
              }
            },
            false
          )

          // Prüfen, ob w2g bereits geladen ist
          if (w.w2gLoaded === undefined) {
            w.w2gLoaded = 0
          }

          // w2g-Skript nachladen, falls noch nicht geladen
          if (w.w2gLoaded < 1 && w.w2g === undefined) {
            const element = d.createElement('script')
            element.type = 'text/javascript'
            element.async = true
            element.src = 'https://lib.wtg-ads.com/lib.single.wtg.min.js'
            d.head.appendChild(element)
            w.w2gLoaded++
          }

          // Aufruf der w2g.single-Funktion, falls w2g bereits definiert ist
          if (w.w2g !== undefined && typeof w.w2g.single === 'function') {
            w.w2g.single(domain, slot, parent)
          }
        }
      }
    },
    selectRandomAdvertiser(advertisers) {
      const randomNumber = Math.random()
      let cumulativeProbability = 0

      for (const advertiser of advertisers) {
        cumulativeProbability += advertiser.percentage / 100
        if (randomNumber < cumulativeProbability) {
          return advertiser
        }
      }
      return advertisers[0]
    },
  },
}
</script>

<style lang="scss">
.adslot {
  overflow: hidden;

  &__leaderboard,
  &__footer {
    max-width: 1137px !important;
    max-height: 280px;
    @media (max-width: 599px) {
      width: calc(100vw - 24px);
    }
  }

  &__sidebar,
  &__modal-solved {
    max-width: 351px !important;
    max-height: 280px;
  }

  &__sticky {
    &-skyscraper {
      position: fixed;
      right: calc(50vw + 585px);
      max-width: 160px;
      max-height: 600px;
      top: 50%;
      margin-top: -300px;
      width: 160px;
    }
  }

  &__content {
    &-zero,
    &-first,
    &-second,
    &-second,
    &-second,
    &-last {
      max-height: 280px;
      @media (max-width: 599px) {
        width: calc(100vw - 24px);
      }
      @media (min-width: 600px) {
        max-width: 463px !important;
      }
      @media (min-width: 1521px) {
        max-width: 728px !important;
      }
    }
  }
}
</style>
