<template>
  <v-app>
    <loader />
    <app-body>
      <router-view></router-view>
    </app-body>
  </v-app>
</template>

<script>
import AppBody from '@/components/app/Body.vue'
import Loader from '@/components/Loader.vue'
import jwt_decode from 'jwt-decode'
import { Com } from '@/util'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    AppBody,
    Loader,
  },
  mounted() {
    this.$nextTick(() => {
      // Now LazyLoading
      this.hideLoader()
    })
  },
  beforeMount() {
    if (this.$cookies.isKey('darkMode') && this.$cookies.get('darkMode') === 'true') {
      this.$vuetify.theme.dark = true
    }
    this.authenticationState()
  },
  methods: {
    ...mapActions(['hideLoader', 'setProfile', 'setAuthenticationExpires']),
    async authenticationState() {
      if (this.$cookies.isKey('authenticationToken')) {
        const authenticationToken = this.$cookies.get('authenticationToken')
        const authenticationTokenDecoded = jwt_decode(authenticationToken)

        if (authenticationTokenDecoded.exp < Date.now() / 1000) {
          this.setAuthenticationExpires(0)
          this.$cookies.remove('authenticationToken')
          return false
        }
        this.setAuthenticationExpires(authenticationTokenDecoded.exp)
        const com = new Com()
        const profile = await com.getProfile()
        this.setProfile(profile.data)
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@fortawesome/fontawesome-svg-core/styles.css';
@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/SourceSans3-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/SourceSans3-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/SourceSans3-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/SourceSans3-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Yanone Kaffeesatz';
  src: url('/assets/fonts/YanoneKaffeesatz-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Mono';
  src: url('/assets/fonts/RobotoMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Mono';
  src: url('/assets/fonts/RobotoMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@import 'src/styles/variables.scss';

* {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  touch-action: manipulation;
}

#app {
  font-family: 'Source Sans Pro', Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: 400;
}

.avatar {
  &--image {
    object-fit: cover;
  }
}

.v-card {
  &__title.headline {
    word-break: normal;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.v-alert p {
  line-height: 1.25rem;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 667ms infinite;
  -webkit-animation: fade 667ms infinite;
}
</style>
