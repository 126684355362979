<template>
  <div>
    <login-authenticated v-if="isAuthenticated"></login-authenticated>
    <div v-else class="pt-6">
      <h2 class="d-sr-only">Login</h2>
      <div class="text-center">
        <fa-icon :class="$theme.app.navigation.buttons.open.text" class="mb-4" size="5x" :icon="['fal', 'user-circle']"></fa-icon>
        <p class="login__name mb-3">{{ $t('components.app.login.p_example_name') }}</p>
      </div>
      <login-form></login-form>
    </div>
    <div v-if="isAuthenticated && isSupporter" class="text-center">
      <p class="mb-1">
        <fa-icon :icon="['fas', 'heart']" class="red--text text--accent-3 mr-1" />
        <i18n path="components.app.login.supporter.p_is_supporter">
          <template #supporter>
            <Hyperlink :href="$t('hyperlinks.supporter.href')" :aria-label="$t('hyperlinks.supporter.purpose')">{{
              $t('components.app.login.supporter.supporter')
            }}</Hyperlink>
          </template>
        </i18n>
      </p>
      <p class="text-caption mb-0">Werbefrei bis: {{ formatDate(actualPeriod) }}</p>
    </div>
  </div>
</template>

<script>
import Hyperlink from '@/components/Hyperlink.vue'
import LoginAuthenticated from '@/components/login/Authenticated'
import LoginForm from '@/components/login/Form'
import { mapGetters } from 'vuex'
import { theme } from '@/mixins'
import { settings } from '@/config'

export default {
  name: 'Login',
  components: { LoginAuthenticated, LoginForm, Hyperlink },
  mixins: [theme],
  computed: {
    ...mapGetters(['profile', 'user', 'actualPeriod', 'isSupporter', 'counter']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
  },
  methods: {
    formatDate(val) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: settings.timeZone }
      return val.toLocaleDateString(this.$i18n.locale, options)
    },
  },
}
</script>

<style lang="scss">
@import 'src/styles/variables.scss';

.login {
  &__avatar {
    &--text {
      font-size: 2rem;
      font-family: $header-font-family;
    }
  }
  &__name {
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1rem;
    letter-spacing: -0.0083333333em;
    font-family: $header-font-family;
  }
  &__caption {
    line-height: 14px !important;
  }
}
</style>
