<template>
  <div>
    <svg
      v-if="countryCode !== 'de-DE'"
      width="100%"
      height="100%"
      viewBox="0 0 140 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 1.5"
    >
      <g id="Now">
        <g id="w">
          <path
            d="M120.532,6.967c0,0 -1.223,1.107 -2.514,2.225c-0.02,0.017 2.559,5.584 4.631,10.156c0.013,0.029 1.102,-1.536 2.184,-2.885c0.017,-0.022 0.739,-2.76 0.739,-2.76c0,0 3.549,5.434 3.564,5.416c0.95,-1.094 1.93,-2.371 1.933,-2.391c0.671,-3.581 2.435,-10.427 2.435,-10.427c0,0 -1.805,0.462 -2.882,1.491c-0.016,0.015 -0.571,2.517 -1.464,3.681c-0.894,1.165 -0.889,-3.263 -0.889,-3.263c0,0 -2.241,0.913 -2.748,1.475c-0.506,0.562 -1.069,2.497 -1.069,2.497l-3.92,-5.215Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M123.319,5.63l-2.769,1.324c0,0 2.458,4.959 4.219,9.612c0.005,0.013 0.779,-1.343 3.134,-4.094c0.024,-0.028 1.692,1.773 3.187,4.239c0.008,0.012 1.878,-3.521 4.731,-9.262c0.013,-0.026 -0.69,-0.358 -2.269,-1.136c-0.054,-0.026 -1.013,2.856 -2.793,5.994c-0.043,0.076 -0.574,-1.615 -2.469,-4.097c-0.028,-0.037 -0.9,1.587 -2.699,3.634c-0.008,0.009 -1.237,-2.915 -2.272,-6.214Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="o">
          <path
            d="M109.688,6.343c-3.901,2.104 -4.895,4.518 -4.932,5.574c-0.151,4.333 2.819,7.021 5.56,7.021c2.74,0 7.356,-2.417 7.356,-8.249c0,-3.498 -4.719,-4.401 -7.984,-4.346Zm-0.984,4.995c0,0 1.637,-2.136 3.332,-1.196c1.695,0.94 1.152,2.93 0.169,3.632c-0.983,0.701 -3.501,-2.436 -3.501,-2.436Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M112.237,5.562c0,0 -5.321,0.617 -5.319,4.99c0.002,4.373 4.191,5.609 5.389,5.609c1.199,0 5.401,-0.341 5.401,-5.88c0,-3.466 -2.677,-4.719 -5.471,-4.719Zm-2.496,4.833c0,1.494 0.962,2.957 2.529,2.957c1.568,0 3.184,-0.734 3.184,-3.482c0,-1.597 -1.947,-2.422 -2.902,-2.422c-0.955,0 -2.811,0.994 -2.811,2.947Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="N">
          <path
            d="M101.518,1.431c0,0 -1.652,0.613 -2.806,2.043c-0.014,0.017 0.182,5.715 0.182,5.715l-5.159,-7.886c0,0 -1.816,0.63 -2.944,1.567c-0.043,0.036 -0.531,6.744 -0.189,16.304c0,0.016 1.775,0.46 3.91,0.319c0.049,-0.003 1.58,-2.674 1.58,-2.674l-1.614,-4.225c0,0 4.943,6.667 4.956,6.671c2.135,0.557 3.71,0.002 3.71,0.002c0,0 0.87,-0.424 1.386,-1.886c0.001,-0.002 -3.012,-15.95 -3.012,-15.95Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M96.071,16.839c0,0 -2.481,0.054 -2.483,0.035c-0.497,-5.829 0.459,-7.789 0.08,-15.535c-0.002,-0.04 1.259,-0.352 2.578,-0.221c0.028,0.003 1.149,5.674 5.446,11.604c0.007,0.01 0.161,-3.946 -0.263,-11.266c0,-0.006 1.338,-0.445 2.907,-0.081c0.048,0.011 0.386,3.771 0.217,15.932c0,0.03 -2.879,0.004 -2.89,-0.009c-2.68,-3.37 -2.461,-4.716 -5.331,-9.821c-0.009,-0.016 -0.7,7.012 -0.261,9.362Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
      </g>
      <g id="Sudoku">
        <g id="u">
          <path
            d="M76.625,5.686l-3.342,2.654c0,0 -2.525,5.294 -0.332,8.899c1.282,2.107 5.479,2.166 6.914,1.763c0.767,-0.215 4.802,-2.414 5.465,-4.525c0.814,-2.589 -2.882,-6.89 -2.882,-6.89c0,0 -1.976,1.445 -2.314,2.019c-0.338,0.575 1.44,5.434 -1.155,5.647c-2.596,0.212 -2.354,-9.567 -2.354,-9.567Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M79.226,6.637l-2.594,-0.927c0,0 -1.811,5.069 -1.337,6.91c0.474,1.841 1.832,4.39 4.854,4.217c3.022,-0.174 5.028,-0.919 5.326,-3.255c0.299,-2.337 0.669,-3.44 -0.55,-6.61c-0.225,-0.585 -2.519,0.621 -2.519,0.621c0,0 1.503,3.941 0.929,5.262c-0.575,1.321 -1.976,2.588 -3.875,1.807c-1.899,-0.781 -1.547,-3.094 -1.258,-4.145c0.29,-1.051 1.024,-3.88 1.024,-3.88Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="k">
          <path
            d="M63.451,2.829c0,0 -1.346,0.471 -2.85,2.203c-0.016,0.018 0.01,5.165 0.007,14.609c0,0.015 1.071,-0.267 3.112,-0.227c0.052,0.001 1.371,-1.494 1.96,-2.148c0.023,-0.026 -0.039,-2.327 -0.039,-2.327c0,0 0.653,1.205 1.817,3.704c0.027,0.059 1.267,-0.655 2.477,-1.65c0.837,-0.689 -2.829,-8.437 -2.829,-8.437l3.722,-4.621c0,0 -2.217,0.619 -3.736,1.54c-0.532,0.322 -2.095,3.367 -2.095,3.367l-1.546,-6.013Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M66.154,2.671c0,0 -1.226,0.176 -2.787,0.168c-0.052,-0.001 0.154,8.499 -0.411,14.571c-0.008,0.091 1.986,-0.284 2.818,-0.117c0.021,0.004 -0.428,-4.452 -0.072,-6.253c0.088,-0.45 1.04,-0.856 1.04,-0.856c0,0 1.78,3.232 3.149,6.874c0.018,0.049 0.978,-1.088 2.048,-1.931c0.057,-0.045 -1.36,-3.599 -3.874,-6.113c-0.023,-0.023 1.775,-1.506 4.119,-2.86c0.076,-0.044 -0.408,-0.907 -1.39,-2.162c-0.018,-0.022 -2.199,2.272 -4.944,4.284c-0.057,0.042 -0.116,-4.736 0.304,-5.605Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="o1" serif:id="o">
          <path
            d="M52.328,6.343c-3.901,2.104 -4.895,4.518 -4.932,5.574c-0.151,4.333 2.819,7.021 5.56,7.021c2.74,0 7.356,-2.417 7.356,-8.249c0,-3.498 -4.719,-4.401 -7.984,-4.346Zm-0.984,4.995c0,0 1.637,-2.136 3.332,-1.196c1.695,0.94 1.152,2.93 0.169,3.632c-0.983,0.701 -3.501,-2.436 -3.501,-2.436Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M54.877,5.562c0,0 -5.321,0.617 -5.319,4.99c0.002,4.373 4.191,5.609 5.389,5.609c1.199,0 5.401,-0.341 5.401,-5.88c0,-3.466 -2.677,-4.719 -5.471,-4.719Zm-2.496,4.833c0,1.494 0.962,2.957 2.529,2.957c1.568,0 3.184,-0.734 3.184,-3.482c0,-1.597 -1.947,-2.422 -2.902,-2.422c-0.955,0 -2.811,0.994 -2.811,2.947Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="d">
          <path
            d="M41.597,3.96c0,0 -0.232,2.624 0.155,3.87c0.386,1.246 -3.034,-0.894 -3.034,-0.894c0,0 -6.674,0.729 -6.899,6.403c-0.113,2.838 1.988,4.79 3.657,5.461c2.158,0.868 5.929,-0.756 5.929,-0.756c0,0 -0.165,0.836 0.136,1.523c0.023,0.054 3.549,-0.141 3.549,-0.141c0,0 1.107,-0.753 2.19,-2.125c0.013,-0.017 -3.119,-15.664 -3.119,-15.664c0,0 -2.258,1.553 -2.564,2.323Zm-4.88,9.522c0,0 1.637,-2.274 3.255,-1.511c1.619,0.762 1.681,1.366 1.681,1.852c0,0.894 -1.854,1.895 -2.471,1.9c-0.617,0.005 -2.465,-2.241 -2.465,-2.241Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M47.54,1.896c0,0 -2.487,-0.019 -3.394,-0.261c-0.064,-0.017 0.824,5.484 0.354,7.133c-0.009,0.029 -1.934,-1.887 -4.863,-1.955c-2.302,-0.054 -4.717,2.313 -4.671,5.645c0.046,3.333 3.212,4.854 4.993,4.872c1.781,0.018 3.392,-0.815 4.209,-1.708c0.018,-0.02 -0.045,0.615 0.198,1.458c0.019,0.064 2.036,0.222 2.804,0.222c0.351,0 -0.855,-11.931 0.37,-15.406Zm-5.558,7.972c-1.561,-0.702 -3.506,-0.82 -4.103,1.296c-0.597,2.116 0.595,3.422 2.143,3.548c1.549,0.125 3.274,-1.114 4.249,-1.788c0.307,-0.212 -0.058,-0.829 -0.053,-1.343c0.005,-0.514 -1.263,-1.275 -2.236,-1.713Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="u1" serif:id="u">
          <path
            d="M22.268,5.922l-3.341,2.654c0,0 -2.526,5.294 -0.333,8.899c1.283,2.108 5.479,2.166 6.914,1.763c0.768,-0.215 4.802,-2.413 5.466,-4.525c0.813,-2.588 -2.883,-6.89 -2.883,-6.89c0,0 -1.976,1.445 -2.314,2.019c-0.338,0.575 1.441,5.435 -1.155,5.647c-2.596,0.212 -2.354,-9.567 -2.354,-9.567Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M24.869,6.874l-2.593,-0.928c0,0 -1.812,5.07 -1.338,6.91c0.475,1.841 1.832,4.39 4.854,4.217c3.022,-0.174 5.028,-0.918 5.327,-3.255c0.298,-2.337 0.669,-3.44 -0.551,-6.61c-0.225,-0.584 -2.519,0.622 -2.519,0.622c0,0 1.504,3.94 0.929,5.261c-0.575,1.321 -1.976,2.589 -3.875,1.808c-1.898,-0.781 -1.547,-3.095 -1.257,-4.146c0.29,-1.051 1.023,-3.879 1.023,-3.879Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="S">
          <path
            d="M12.914,5.442c0,0 1.719,-0.153 2.48,-1.713c0.762,-1.56 -3.201,-3.142 -3.201,-3.142c0,0 -6.041,-1.174 -7.408,4.261c-0.727,2.893 1.948,4.622 3.927,6.096c1.979,1.475 3.356,1.889 2.719,3.599c-0.638,1.711 -3.864,-1.943 -3.864,-1.943l-2.824,1.847c0,0 -0.77,3.404 -1.363,4.207c-0.592,0.803 11.951,3.295 14.044,-4.832c0.455,-1.766 -7.798,-8.325 -7.798,-8.325c0,0 0.939,-2.662 3.288,-0.055Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M15.13,4.157c0,0 0.753,-0.922 1.007,-2.229c0.003,-0.015 -2.814,-1.427 -4.514,-1.441c-1.7,-0.014 -4.187,0.85 -4.519,3.357c-0.332,2.507 2.034,3.855 3.177,4.399c1.143,0.544 5.004,2.613 4.368,4.423c-0.635,1.81 -2.338,1.934 -4.323,1.229c-1.985,-0.705 -2.798,-1.379 -2.81,-1.321c-0.209,1.04 -0.354,2.78 -1.002,4.149c-0.034,0.072 8.915,2.886 10.619,-2.166c1.45,-4.303 -1.246,-5.254 -4.612,-7.29c-2.082,-1.26 -2.828,-2.578 -2.02,-3.865c0.461,-0.735 2.745,-1.275 4.629,0.755Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
      </g>
    </svg>
    <svg
      v-else
      width="100%"
      height="100%"
      viewBox="0 0 182 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule: evenodd; clip-rule: evenodd; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 1.5"
    >
      <g id="Knacker">
        <g id="r">
          <path
            d="M169.988,6.038l-2.482,2.637c0,0 1.066,2.976 2.396,10.877c0.002,0.015 0.887,0.086 2.546,-0.42c0.016,-0.005 0.987,-0.826 1.369,-2.13c0.01,-0.032 -1.78,-5.617 -1.78,-5.617c0,0 0.674,-1.094 3.824,-0.225c0.026,0.007 1.185,-2.321 1.185,-2.321l-4.056,-0.667l-3.002,-2.134Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M169.926,6.034c0,0 0.887,4.628 2.144,11.316c0.004,0.022 1.773,-0.306 1.772,-0.317c-0.475,-2.773 -0.925,-4.578 -0.406,-6.095c0.699,-2.045 1.691,-2.55 3.627,-2.064c0.009,0.003 0.328,-1.088 0.973,-1.959c0.017,-0.024 -3.131,-2.274 -5.445,0.713c-0.006,0.009 -0.151,-1.106 -0.447,-2.07c-0.024,-0.079 -1.179,0.475 -2.218,0.476Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="e">
          <path
            d="M168.048,14.607l-1.262,3.393c0,0 -3.778,2.489 -7.985,1.6c-4.207,-0.889 -4.287,-5.198 -3.912,-7.404c0.487,-2.863 3.296,-5.523 6.349,-6.067c3.052,-0.543 6.546,4.488 6.546,4.488c0,0 -0.677,1.099 -1.941,2.016c-0.027,0.02 -6.842,1.575 -6.842,1.575l3.175,1.769l5.872,-1.37Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M159.502,12.468l8.278,-1.888c0,0 -0.79,-5.259 -6.354,-4.487c-0.044,0.006 -0.09,0.01 -0.134,0.02c-3.203,0.696 -4.225,4.304 -3.763,7.171c0.23,1.429 1.391,3.265 3.332,3.726c1.94,0.462 4.186,-0.281 7.167,-2.423c0.013,-0.01 -1.372,-2.017 -1.372,-2.017c0,0 -2.251,2.012 -3.915,2.134c-1.265,0.093 -3.063,-0.641 -3.239,-2.236Zm-0.014,-2.248c0,0 1.923,-0.191 5.491,-1.198c0.025,-0.007 -1.135,-1.104 -2.801,-0.884c-1.665,0.221 -2.69,2.082 -2.69,2.082Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="k">
          <path
            d="M146.126,2.822c0,0 -1.345,0.471 -2.85,2.203c-0.015,0.018 0.01,5.165 0.008,14.609c0,0.014 1.07,-0.268 3.111,-0.227c0.052,0 1.371,-1.494 1.961,-2.148c0.023,-0.026 -0.039,-2.328 -0.039,-2.328c0,0 0.652,1.206 1.816,3.705c0.028,0.058 1.267,-0.655 2.477,-1.65c0.838,-0.689 -2.829,-8.437 -2.829,-8.437l3.722,-4.621c0,0 -2.217,0.619 -3.736,1.54c-0.531,0.322 -2.094,3.367 -2.094,3.367l-1.547,-6.013Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M148.829,2.664c0,0 -1.225,0.176 -2.786,0.167c-0.052,0 0.153,8.5 -0.411,14.572c-0.008,0.091 1.986,-0.284 2.817,-0.117c0.022,0.004 -0.427,-4.452 -0.072,-6.254c0.089,-0.449 1.041,-0.855 1.041,-0.855c0,0 1.779,3.232 3.148,6.874c0.019,0.049 0.979,-1.088 2.048,-1.931c0.057,-0.045 -1.359,-3.599 -3.873,-6.113c-0.024,-0.023 1.774,-1.506 4.119,-2.86c0.075,-0.044 -0.408,-0.907 -1.39,-2.162c-0.018,-0.022 -2.2,2.272 -4.944,4.284c-0.057,0.042 -0.117,-4.736 0.303,-5.605Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="c">
          <path
            d="M138.334,11.001c0,0 2.071,-0.807 2.87,-2.004c0.799,-1.197 -3.204,-3.58 -3.204,-3.58c0,0 -2.622,0.117 -4.63,1.629c-1.938,1.458 -2.805,3.771 -2.598,6.427c0.185,2.371 3.029,6.06 6.007,5.738c3.738,-0.404 4.786,-1.445 6.93,-3.821c0.021,-0.023 -6.431,-1.392 -6.431,-1.392l-2.151,-2.914c0,0 1.075,-2.289 3.207,-0.083Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M142.62,7.517l-1.427,1.532c0,0 -1.572,-1.368 -2.671,-1.214c-1.098,0.154 -2.61,1.527 -2.126,3.24c0.484,1.714 2.319,3.521 5.087,1.847c0.043,-0.026 2.219,2.475 2.219,2.475c0,0 -3.047,1.87 -5.461,1.066c-1.805,-0.601 -4.206,-1.89 -4.527,-4.812c-0.427,-3.884 2.122,-6.105 4.529,-6.202c2.586,-0.106 4.325,2.108 4.377,2.068Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="a">
          <path
            d="M121.78,6.969c0,0 -2.907,0.797 -4.281,2.529c-0.966,1.219 -1.716,3.036 -0.872,6.035c0.707,2.511 2.727,3.271 4.651,3.604c1.922,0.333 2.716,-0.067 3.726,-0.801c0.035,-0.026 0.055,0.489 0.361,0.87c0.011,0.014 2.598,0.718 5.466,-1.169c0.009,-0.006 -2.562,-9.176 -2.562,-9.176l-6.489,-1.892Zm-1.394,4.736c0,0 1.646,-1.256 2.631,-1.242c0.984,0.015 1.601,0.983 1.601,0.983l0.058,3.37c0,0 -1.392,0.587 -3.026,0.352c-1.634,-0.236 -1.264,-3.463 -1.264,-3.463Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M130.37,5.893c0,0 -2.231,0.238 -3.541,0.016c-0.016,-0.002 0.213,0.792 -0.046,1.631c-0.021,0.069 -1.592,-0.928 -3.649,-0.822c-3.483,0.18 -4.515,3.299 -4.492,5.971c0.022,2.672 1.912,5.09 5.009,5.178c1.617,0.046 2.82,-0.468 4.369,-1.568c0.016,-0.012 -0.162,1.74 -0.151,1.739c1.626,-0.115 2.976,0.065 2.974,0.04c-0.354,-4.692 -0.738,-8.187 -0.473,-12.185Zm-3.091,4.001c0,0 0.438,2.563 0.296,4.179c-0.003,0.045 -2.272,1.922 -4.012,1.222c-0.983,-0.395 -2.179,-1.635 -2.085,-3.357c0.095,-1.722 1.211,-2.829 2.681,-2.783c1.471,0.046 2.582,0.589 3.12,0.739Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="n">
          <path
            d="M107.338,6.296c0,0 -1.426,0.327 -2.78,1.667c-0.009,0.009 -0.271,5.449 0.394,10.899c0.001,0.012 1.253,0.505 3.817,0.291c0.036,-0.003 0.672,-0.834 1.499,-3.151c0.013,-0.035 -0.537,-5.416 -0.537,-5.416l1.221,-1.578c0,0 0.854,3.938 0.218,10.147c-0.003,0.037 1.659,-0.103 3.297,0.249c0.034,0.007 0.957,-1.604 1.665,-3.63c0.317,-0.908 -2.848,-7.864 -2.848,-7.864l-3.936,0.849l-2.01,-2.463Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M107.289,6.341c0,0 0.616,5.72 0.052,9.499c-0.006,0.036 1.323,0.38 2.97,0.138c0.016,-0.002 -0.096,-5.21 1.166,-6.202c1.376,-1.083 1.846,0.708 1.966,1.1c0.441,1.45 0.324,2.237 -0.243,4.881c-0.016,0.074 1.273,-0.17 2.96,0.047c0.02,0.002 1.143,-4.761 -1.332,-8.472c-0.878,-1.317 -3.526,-1.973 -5.003,0.748c-0.018,0.033 0.207,-0.745 0.015,-1.565c-0.001,-0.004 -1.114,-0.058 -2.551,-0.174Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="K">
          <path
            d="M92.859,0.743c0,0 -1.018,0.65 -2.523,2.382c-0.015,0.018 0.084,6.983 0.081,16.427c0,0.015 0.98,-0.107 3.021,-0.067c0.051,0.001 1.408,-1.352 1.997,-2.006c0.023,-0.026 -0.176,-7.564 -0.176,-7.564c0,0 3.159,7.056 4.324,9.555c0.027,0.059 1.334,-0.876 2.544,-1.871c0.838,-0.689 -3.77,-10.337 -3.77,-10.337l4.816,-6.777c0,0 -2.097,-0.48 -3.616,0.441c-0.531,0.322 -5.091,5.311 -5.091,5.311l-1.607,-5.494Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M95.84,0.649c0,0 -1.457,0.114 -3.018,0.106c-0.052,0 0.467,10.683 -0.097,16.754c-0.009,0.092 1.888,-0.17 2.72,-0.003c0.021,0.004 -0.141,-4.059 0.214,-5.861c0.089,-0.449 1.488,-1.49 1.488,-1.49c0,0 3.594,3.808 4.963,7.451c0.018,0.049 0.975,-0.798 2.044,-1.641c0.057,-0.045 -2.246,-5.136 -4.76,-7.65c-0.024,-0.024 2.685,-3.685 5.03,-5.04c0.075,-0.043 -0.228,-1.51 -1.21,-2.764c-0.018,-0.023 -4.75,4.849 -7.495,6.861c-0.057,0.042 -0.299,-5.855 0.121,-6.723Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
      </g>
      <g id="Sudoku">
        <g id="u">
          <path
            d="M76.5,5.679l-3.341,2.654c0,0 -2.526,5.294 -0.332,8.899c1.282,2.107 5.478,2.165 6.914,1.763c0.767,-0.215 4.801,-2.414 5.465,-4.525c0.813,-2.589 -2.883,-6.89 -2.883,-6.89c0,0 -1.976,1.445 -2.313,2.019c-0.339,0.574 1.44,5.434 -1.156,5.646c-2.595,0.213 -2.354,-9.566 -2.354,-9.566Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M79.101,6.63l-2.593,-0.927c0,0 -1.812,5.069 -1.338,6.91c0.475,1.84 1.833,4.39 4.855,4.216c3.022,-0.173 5.027,-0.918 5.326,-3.254c0.299,-2.337 0.669,-3.44 -0.551,-6.61c-0.225,-0.585 -2.518,0.621 -2.518,0.621c0,0 1.503,3.941 0.928,5.262c-0.574,1.321 -1.975,2.588 -3.874,1.807c-1.899,-0.781 -1.548,-3.095 -1.258,-4.146c0.29,-1.05 1.023,-3.879 1.023,-3.879Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="k1" serif:id="k">
          <path
            d="M63.326,2.822c0,0 -1.345,0.471 -2.85,2.203c-0.015,0.018 0.01,5.165 0.008,14.609c0,0.014 1.07,-0.268 3.111,-0.227c0.052,0 1.371,-1.494 1.961,-2.148c0.023,-0.026 -0.039,-2.328 -0.039,-2.328c0,0 0.652,1.206 1.816,3.705c0.028,0.058 1.267,-0.655 2.477,-1.65c0.838,-0.689 -2.829,-8.437 -2.829,-8.437l3.722,-4.621c0,0 -2.217,0.619 -3.736,1.54c-0.531,0.322 -2.094,3.367 -2.094,3.367l-1.547,-6.013Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M66.029,2.664c0,0 -1.225,0.176 -2.786,0.167c-0.052,0 0.153,8.5 -0.411,14.572c-0.008,0.091 1.986,-0.284 2.817,-0.117c0.022,0.004 -0.427,-4.452 -0.072,-6.254c0.089,-0.449 1.041,-0.855 1.041,-0.855c0,0 1.779,3.232 3.148,6.874c0.019,0.049 0.979,-1.088 2.048,-1.931c0.057,-0.045 -1.359,-3.599 -3.873,-6.113c-0.024,-0.023 1.774,-1.506 4.119,-2.86c0.075,-0.044 -0.408,-0.907 -1.39,-2.162c-0.018,-0.022 -2.2,2.272 -4.944,4.284c-0.057,0.042 -0.117,-4.736 0.303,-5.605Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="o">
          <path
            d="M52.204,6.336c-3.902,2.103 -4.895,4.518 -4.932,5.574c-0.151,4.333 2.819,7.021 5.559,7.021c2.741,0 7.357,-2.417 7.357,-8.249c0,-3.498 -4.719,-4.402 -7.984,-4.346Zm-0.984,4.995c0,0 1.637,-2.136 3.332,-1.196c1.694,0.94 1.152,2.93 0.169,3.632c-0.984,0.701 -3.501,-2.436 -3.501,-2.436Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M54.753,5.555c0,0 -5.321,0.617 -5.319,4.99c0.002,4.373 4.19,5.609 5.389,5.609c1.199,0 5.4,-0.341 5.4,-5.88c0,-3.466 -2.676,-4.719 -5.47,-4.719Zm-2.497,4.833c0,1.494 0.963,2.957 2.53,2.957c1.567,0 3.184,-0.734 3.184,-3.482c0,-1.597 -1.948,-2.422 -2.902,-2.422c-0.955,0 -2.812,0.994 -2.812,2.947Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="d">
          <path
            d="M41.472,3.952c0,0 -0.232,2.625 0.155,3.87c0.387,1.247 -3.033,-0.893 -3.033,-0.893c0,0 -6.675,0.729 -6.9,6.403c-0.112,2.838 1.988,4.79 3.658,5.461c2.157,0.868 5.929,-0.756 5.929,-0.756c0,0 -0.166,0.836 0.135,1.523c0.024,0.054 3.55,-0.141 3.55,-0.141c0,0 1.106,-0.753 2.19,-2.125c0.013,-0.017 -3.119,-15.665 -3.119,-15.665c0,0 -2.259,1.554 -2.565,2.323Zm-4.88,9.523c0,0 1.637,-2.275 3.256,-1.512c1.619,0.763 1.681,1.367 1.681,1.852c0,0.895 -1.854,1.896 -2.471,1.901c-0.617,0.005 -2.466,-2.241 -2.466,-2.241Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M47.416,1.889c0,0 -2.487,-0.019 -3.395,-0.261c-0.064,-0.017 0.824,5.484 0.354,7.133c-0.008,0.029 -1.933,-1.887 -4.862,-1.955c-2.303,-0.054 -4.717,2.312 -4.671,5.645c0.045,3.333 3.212,4.854 4.993,4.872c1.781,0.018 3.392,-0.815 4.208,-1.708c0.018,-0.02 -0.044,0.614 0.199,1.458c0.018,0.064 2.036,0.222 2.803,0.222c0.352,0 -0.854,-11.931 0.371,-15.406Zm-5.558,7.972c-1.562,-0.702 -3.506,-0.82 -4.103,1.296c-0.597,2.116 0.595,3.422 2.143,3.547c1.548,0.126 3.274,-1.113 4.248,-1.787c0.308,-0.212 -0.057,-0.829 -0.052,-1.343c0.004,-0.514 -1.264,-1.275 -2.236,-1.713Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="u1" serif:id="u">
          <path
            d="M22.144,5.915l-3.342,2.654c0,0 -2.525,5.294 -0.332,8.899c1.282,2.107 5.478,2.166 6.914,1.763c0.767,-0.215 4.801,-2.413 5.465,-4.525c0.814,-2.588 -2.882,-6.89 -2.882,-6.89c0,0 -1.976,1.445 -2.314,2.019c-0.338,0.575 1.44,5.434 -1.155,5.647c-2.596,0.212 -2.354,-9.567 -2.354,-9.567Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M24.745,6.867l-2.594,-0.928c0,0 -1.812,5.069 -1.337,6.91c0.474,1.841 1.832,4.39 4.854,4.217c3.022,-0.174 5.027,-0.918 5.326,-3.255c0.299,-2.337 0.669,-3.44 -0.55,-6.61c-0.226,-0.584 -2.519,0.621 -2.519,0.621c0,0 1.503,3.941 0.929,5.262c-0.575,1.321 -1.976,2.589 -3.875,1.808c-1.899,-0.781 -1.548,-3.095 -1.258,-4.146c0.29,-1.051 1.024,-3.879 1.024,-3.879Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
        <g id="S">
          <path
            d="M12.79,5.435c0,0 1.719,-0.153 2.48,-1.713c0.761,-1.56 -3.201,-3.142 -3.201,-3.142c0,0 -6.042,-1.174 -7.408,4.261c-0.728,2.893 1.948,4.622 3.927,6.096c1.979,1.475 3.356,1.889 2.718,3.599c-0.637,1.711 -3.863,-1.943 -3.863,-1.943l-2.825,1.847c0,0 -0.77,3.403 -1.362,4.207c-0.592,0.803 11.95,3.294 14.044,-4.832c0.455,-1.766 -7.798,-8.325 -7.798,-8.325c0,0 0.938,-2.662 3.288,-0.055Z"
            style="stroke: #000; stroke-width: 0.24px"
          />
          <path
            d="M15.005,4.149c0,0 0.753,-0.921 1.008,-2.228c0.002,-0.015 -2.815,-1.427 -4.515,-1.441c-1.7,-0.015 -4.187,0.85 -4.519,3.357c-0.332,2.507 2.035,3.855 3.178,4.399c1.142,0.544 5.004,2.613 4.368,4.423c-0.636,1.81 -2.338,1.934 -4.323,1.229c-1.985,-0.705 -2.799,-1.379 -2.81,-1.321c-0.21,1.04 -0.354,2.78 -1.003,4.149c-0.034,0.072 8.916,2.886 10.619,-2.166c1.451,-4.303 -1.245,-5.254 -4.611,-7.29c-2.082,-1.26 -2.829,-2.578 -2.021,-3.866c0.461,-0.734 2.746,-1.274 4.629,0.755Z"
            style="fill: #fff; stroke: #000; stroke-width: 0.24px"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    countryCode: {
      type: String,
      default: 'de-DE',
    },
  },
}
</script>

<style lang="scss" scoped></style>
