<template>
  <v-dialog v-model="splashScreen" persistent max-width="1000">
    <v-card>
      <v-card-title class="headline">{{ $t('components.splash_screen.dialog_title') }}</v-card-title>

      <v-card-text>
        <Release20212></Release20212>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close()">{{ $t('globals.btn_continue') }}</v-btn>
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
import Release20212 from '@/components/release-notes/release2021_2'
import { version } from '@/config'

export default {
  name: 'SplashScreen',
  components: {
    Release20212,
  },
  data() {
    return {
      splashScreen: false,
    }
  },
  computed: {
    displayUntil() {
      return new Date(version.splashscreen.displayUntil)
    },
    version() {
      return version.actual
    },
    splashCookie() {
      return version.splashCookie
    },
  },
  beforeMount() {
    if (
      new Date() < this.displayUntil &&
      !(this.$cookies.isKey('hideSplashScreen') && this.$cookies.get('hideSplashScreen') === this.splashCookie)
    )
      this.splashScreen = true
  },
  methods: {
    close() {
      this.$cookies.set('hideSplashScreen', this.splashCookie, '14d')
      this.splashScreen = false
    },
  },
}
</script>

<style scoped></style>
