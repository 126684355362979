const footer = {
  buttons: [
    {
      name: 'home',
      icon: ['fad', 'home-heart'],
      href: 'hyperlinks.home.href',
      purpose: 'hyperlinks.home.purpose',
    },
    {
      name: 'instructions',
      icon: ['fad', 'question-circle'],
      href: 'hyperlinks.instructions.href',
      purpose: 'hyperlinks.instructions.purpose',
    },
    {
      name: 'faq',
      icon: ['fad', 'comments'],
      href: 'hyperlinks.faq.href',
      purpose: 'hyperlinks.faq.purpose',
    },
    {
      name: 'contact',
      icon: ['fad', 'at'],
      href: 'hyperlinks.contact.href',
      purpose: 'hyperlinks.contact.purpose',
    },
    {
      name: 'legal',
      icon: ['fad', 'file-alt'],
      href: 'hyperlinks.legal.href',
      purpose: 'hyperlinks.legal.purpose',
    },
    {
      name: 'terms',
      icon: ['fad', 'gavel'],
      href: 'hyperlinks.terms.href',
      purpose: 'hyperlinks.terms.purpose',
    },
  ],
}

export { footer }
