export default {
  state: {
    sudoku: {
      activeCellId: null,
      checkInDigit: new Array(81),
      checked: 0,
      dailyPuzzleDate: null,
      entries: new Array(81),
      id: null,
      notes: new Array(81),
      puzzle: '',
      remainingSeconds: 0,
      solution: '',
      solved: false,
      stars: null,
      warnings: new Array(81),
    },
    saving: {
      active: false,
      counter: 0,
    },
    lastSaved: {
      puzzles: [],
    },
    statistics: {
      solvedPuzzlesTotal: 0,
      lastWeek: [0, 0, 0, 0, 0, 0, 0],
    },
  },
  getters: {
    getActiveCellId(state) {
      return state.sudoku.activeCellId
    },
    getLastSavedPuzzles(state) {
      return state.lastSaved.puzzles
    },
    progress(state) {
      const puzzle = state.sudoku.puzzle.split('').filter((val) => val === '0').length
      const entries = state.sudoku.entries.filter((val) => typeof val !== 'undefined' && val !== null).length
      return Math.floor((entries * 100) / puzzle)
    },
    saving(state) {
      return state.saving
    },
    statistics(state) {
      return state.statistics
    },
    sudoku(state) {
      return state.sudoku
    },
  },
  mutations: {
    INCREMENT_CHECKED(state) {
      state.sudoku.checked++
    },
    INCREMENT_SAVING_COUNTER(state) {
      state.saving.counter++
    },
    RESET_SAVING_COUNTER(state) {
      state.saving.counter = 0
    },
    SET_ACTIVE_CELL_ID(state, id) {
      state.sudoku.activeCellId = id
    },
    SET_CHECK_IN_DIGIT(state, payload) {
      state.sudoku.checkInDigit.splice(payload.cellId, 1, payload.digit)
    },
    SET_ENTRY(state, payload) {
      state.sudoku.entries.splice(payload.elementId, 1, payload.val)
    },
    SET_LAST_SAVED_PUZZLES(state, payload) {
      state.lastSaved.puzzles = payload
    },
    SET_NOTE(state, payload) {
      // Use splice instead of state.sudoku.notes[payload.elementId] = payload.val
      state.sudoku.notes.splice(payload.elementId, 1, payload.val)
    },
    SET_SAVING_ACTIVE(state, val) {
      state.saving.active = val
    },
    SET_SOLVED(state, val) {
      state.sudoku.solved = val
    },
    SET_SUDOKU(state, payload) {
      state.sudoku = {
        ...state.sudoku,
        ...payload,
      }
    },
    SET_SUDOKU_STATISTICS(state, payload) {
      state.statistics = {
        ...payload,
      }
    },
    SET_WARNING(state, payload) {
      state.sudoku.warnings.splice(payload.elementId, 1, payload.val)
    },
  },
  actions: {
    incrementChecked({ commit }) {
      commit('INCREMENT_CHECKED')
      commit('INCREMENT_SAVING_COUNTER')
    },
    initSudoku({ commit }, payload) {
      commit('SET_SUDOKU', {
        activeCellId: null,
        checkInDigit: new Array(81),
        checked: 0,
        dailyPuzzleDate: payload.dailyPuzzleDate,
        entries: new Array(81),
        id: payload.id,
        notes: new Array(81),
        puzzle: payload.puzzle,
        remainingSeconds: payload.remainingSeconds,
        solution: payload.solution,
        solved: false,
        stars: payload.stars,
        warnings: new Array(81),
      })
    },
    resetSavingCounter({ commit }) {
      commit('RESET_SAVING_COUNTER')
    },
    setActiveCellId({ commit }, id) {
      commit('SET_ACTIVE_CELL_ID', id)
    },
    setCheckInDigit({ commit }, payload) {
      commit('SET_CHECK_IN_DIGIT', payload)
    },
    setEntry({ commit }, payload) {
      commit('SET_ENTRY', payload)
      commit('INCREMENT_SAVING_COUNTER')
    },
    setLastSavedPuzzles({ commit }, payload) {
      commit('SET_LAST_SAVED_PUZZLES', payload)
    },
    setNote({ commit }, payload) {
      commit('SET_NOTE', payload)
      commit('INCREMENT_SAVING_COUNTER')
    },
    setSavingActive({ commit }, val) {
      commit('SET_SAVING_ACTIVE', val)
    },
    setSolved({ commit }) {
      commit('SET_SOLVED', true)
    },
    setSudoku({ commit }, payload) {
      commit('SET_SUDOKU', payload)
    },
    setSudokuStatistics({ commit }, payload) {
      commit('SET_SUDOKU_STATISTICS', payload)
    },
    setWarning({ commit }, payload) {
      commit('SET_WARNING', payload)
    },
  },
  modules: {},
}
