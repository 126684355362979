import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import de from 'vuetify/es5/locale/de'

const vuetify = new Vuetify({
  breakpoints: {
    xl: false,
  },
  theme: {
    dark: false,
  },
  lang: {
    locales: { de },
    current: 'de',
  },
})

import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, { vuetify })

export { vuetify }
