const lazy = {
  data: () => {
    return {
      isMounted: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true
    })
  },
}

export { lazy }
