export default {
  state: {
    speedMode: true,
  },
  getters: {
    getSpeedMode(state) {
      return state.speedMode
    },
  },
  mutations: {
    SET_SPEED_MODE(state, val) {
      state.speedMode = val
    },
  },
  actions: {
    setSpeedMode({ commit }, val) {
      commit('SET_SPEED_MODE', val)
    },
  },
  modules: {},
}
