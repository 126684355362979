<template>
  <div>
    <v-menu left :offset-y="true">
      <template #activator="{ on, attrs }">
        <v-btn small fab color="transparent" :aria-label="$t('components.logout.menu_label')" elevation="0" v-bind="attrs" v-on="on">
          <fa-icon class="fa-fw" size="2x" :icon="['far', 'ellipsis-h']" />
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="goToProfile()">
            <v-list-item-icon class="mr-1">
              <fa-icon class="fa-fw" :icon="['fad', 'user-graduate']"></fa-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('components.logout.list_item_update_profile') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item @click="logout()">
            <v-list-item-icon class="mr-1">
              <fa-icon class="fa-fw" :icon="['fad', 'power-off']"></fa-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('components.logout.list_item_logout') }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Com } from '@/util'

export default {
  name: 'Logout',
  methods: {
    ...mapActions(['setAuthenticationExpires', 'setPurpose', 'showLoader', 'showComAlert']),
    async logout() {
      this.setPurpose(this.$t('components.logout.list_item_logout'))
      this.showLoader()
      const com = new Com()
      await com.logout()
      setTimeout(() => {
        location.href = this.$t('hyperlinks.home.href')
      }, 1)
    },
    goToProfile() {
      this.setPurpose(this.$t('hyperlinks.profile.purpose'))
      this.showLoader()
      setTimeout(() => {
        location.href = this.$t('hyperlinks.profile.href')
      }, 1)
    },
  },
}
</script>
<style lang="scss"></style>
