<template>
  <div>
    <h2>{{ $t('components.app.info.h2_info') }}</h2>
    <div :class="$theme.app.info.backgroundColor" class="pa-3 mb-4">
      <h3>{{ $t('components.app.info.h3_statistics') }}</h3>
      <div v-if="!isAuthenticated">
        <p>{{ $t('components.app.info.p_not_authenticated') }}</p>
      </div>

      <div>
        <p>
          <fa-icon class="green--text fa-fw mr-1" :icon="['far', 'check']" transform="grow-3"></fa-icon
          >{{ $t('components.app.info.p_solved_puzzles') }}<sup>[<a href="#fn01" role="doc-noteref">1</a>]</sup>
          <span :class="$theme.app.info.statistics.solvedPuzzlesTotal" class="ml-3 px-2 py-1 rounded"
            ><strong>{{ formatCounter(statistics.solvedPuzzlesTotal) }}</strong></span
          >
          <hyperlink :href="$t('hyperlinks.solved_puzzles.href')" :aria-label="$t('hyperlinks.solved_puzzles.purpose')"
            ><fa-icon class="fa-fw ml-2" :icon="['fad', 'eye']" transform="grow-2"></fa-icon
          ></hyperlink>
        </p>
        <h4>Letzte 7 Tage</h4>
        <v-sheet :color="$theme.app.info.statistics.backgroundColor" class="my-3">
          <v-sparkline
            :value="statistics.lastWeek"
            stroke-linecap="round"
            color="blue-grey"
            smooth
            label-size="13"
            padding="24"
            height="100"
          >
            <template #label="item">{{ item.value }}</template>
          </v-sparkline>
        </v-sheet>
        <v-divider class="mt-6 mb-1" />
        <div class="info--span-footnote text-caption">
          <span id="fn01" class="mr-1">1.</span>
          {{ $t('components.app.info.footnote_1', { dailySudoku: $t('components.app.info.daily_sudoku') }) }}
        </div>
      </div>
    </div>
    <v-divider class="my-3" />
  </div>
</template>

<script>
import { Com } from '@/util'
import { mapGetters } from 'vuex'
import { theme } from '@/mixins'
import Hyperlink from '@/components/Hyperlink'

export default {
  name: 'AppInfo',
  components: {
    Hyperlink,
  },
  mixins: [theme],
  computed: {
    ...mapGetters(['statistics', 'user']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
  },
  async mounted() {
    if (this.isAuthenticated) {
      const com = new Com()
      const sudokuStatistics = await com.getSudokuStatistics()
      await this.$store.dispatch('setSudokuStatistics', sudokuStatistics.data)
    }
  },
  methods: {
    formatCounter(number) {
      return new Intl.NumberFormat(this.$i18n.locale).format(number)
    },
  },
}
</script>

<style lang="scss">
.info {
  &--span-footnote {
    line-height: 1rem !important;
  }
}
</style>
