export default {
  state: {
    invertMode: true,
  },
  getters: {
    getInvertMode(state) {
      return state.invertMode
    },
  },
  mutations: {
    SET_INVERT_MODE(state, val) {
      state.invertMode = val
    },
  },
  actions: {
    setInvertMode({ commit }, val) {
      commit('SET_INVERT_MODE', val)
    },
  },
  modules: {},
}
