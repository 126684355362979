export default {
  state: {
    counter: {
      online: 0,
      today: 0,
      yesterday: 0,
    },
  },
  getters: {
    counter(state) {
      return state.counter
    },
  },
  mutations: {
    SET_COUNTER(state, payload) {
      state.counter = payload
    },
  },
  actions: {
    setCounter({ commit }, payload) {
      commit('SET_COUNTER', payload)
    },
  },
  modules: {},
}
