const navbar = {
  items: [
    {
      title: 'Sudoku Knacker',
      href: 'https://www.sudoku-knacker.de/',
    },
    {
      title: 'Sudoku des Tages',
      href: 'https://www.sudoku-knacker.de/de/sudoku-des-tages',
    },
    {
      title: 'Sudoku 17',
      href: 'https://www.sudoku17.de/',
    },
    {
      title: 'Kakuro',
      href: 'https://www.kakuro-knacker.de/',
    },
    {
      title: 'Vier Gewinnt',
      href: 'https://www.4-gewinnt.de/',
    },
    {
      title: 'Solitär',
      href: 'https://www.solitaer-knacker.de/',
    },
  ],
}

export { navbar }
