<template>
  <v-footer padless>
    <v-card :color="$theme.app.footer.backgroundColor" flat tile width="100%">
      <v-card-text class="pa-2">
        <v-divider v-if="$vuetify.breakpoint.mdAndUp" class="mb-6"></v-divider>
        <div class="text-center mb-6 mx-4">
          <Adslot ad-unit="footer" ad-class="adslot__footer" />
        </div>
        <div class="text-center mb-6">
          <btn v-for="(button, index) in buttons" :key="index" :href="$t(button.href)" :aria-label="$t(button.purpose)">
            <fa-icon v-if="$vuetify.breakpoint.mdAndUp" class="mr-1" size="lg" :icon="button.icon"></fa-icon>
            <i18n :path="`components.app.footer.buttons.${button.name}`"></i18n>
          </btn>
        </div>
        <div class="text-center">
          <p>
            <i18n path="components.app.footer.made_with_love">
              <template #heartIcon>
                <fa-icon :icon="['fas', 'heart']" class="red--text text--accent-3" />
              </template>
              <template #mugIcon>
                <fa-icon :icon="['fad', 'coffee-togo']" class="brown--text text--lighten-2" />
              </template>
            </i18n>
          </p>
          <CologneSkyline class="mb-3" />

          <p id="component-footer-copyright" class="text-center mb-3">
            <i18n path="components.app.footer.copyright">
              <template #copyrightIcon>
                <fa-icon :icon="['far', 'copyright']" size="sm" />
              </template>
              <template #foundingYear>
                {{ copyright.foundingYear }}
              </template>
              <template #currentYear>
                {{ currentYear }}
              </template>
            </i18n>
            <span v-if="$vuetify.breakpoint.smAndUp">・ {{ copyright.name }} ・ {{ copyright.company }}</span
            ><span v-if="$vuetify.breakpoint.mdAndUp">・ {{ $t('components.app.footer.all_rights_reserved') }}</span>
          </p>
          <p v-if="$vuetify.breakpoint.xsOnly" class="mb-3">
            {{ copyright.name }}
            <br />
            {{ copyright.company }}
          </p>
          <p v-if="$vuetify.breakpoint.smAndDown">
            {{ $t('components.app.footer.all_rights_reserved') }}
          </p>
          <div class="my-3" style="transform: scale(0.6)">
            <div id="ezoic-certification-container"></div>
          </div>
          <p>
            <hyperlink :href="$t('hyperlinks.release_notes.href') + path" :aria-label="$t('hyperlinks.release_notes.purpose')">
              Version {{ version }}
            </hyperlink>
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import CologneSkyline from '@/components/svg/CologneSkyline.vue'
import Btn from '@/components/Btn.vue'
import Hyperlink from '@/components/Hyperlink'
import { copyright, footer, version } from '@/config/index.js'
import { theme } from '@/mixins'
import Adslot from '@/components/Adslot.vue'

export default {
  name: 'AppFooter',
  components: {
    CologneSkyline,
    Btn,
    Hyperlink,
    Adslot,
  },
  mixins: [theme],
  inject: ['theme'],
  data: () => {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
  computed: {
    copyright() {
      return copyright
    },
    buttons() {
      return footer.buttons
    },
    version() {
      return version.actual
    },
    path() {
      return version.path
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  line-height: 1rem;
}
.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 24px;
}
.image-skyline {
  max-width: 200px;
  max-height: 60px;
}
</style>
