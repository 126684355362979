const legal = {
  firstName: 'Marco',
  lastName: 'Volberg',
  company: 'Internet Services & Website Marketing',
  streetAddress: 'Berrenrather Str. 16',
  city: 'Erftstadt',
  postalCode: '50374',
  country: 'Deutschland',
  vatId: 'DE254715258',
  emailAddress: 'info@volberg.de',
  phoneNumber: '02235 7948257',
}

export { legal }
