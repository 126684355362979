const settings = {
  assets: {
    url: process.env.BASE_URL + 'assets/',
  },
  cdn: {
    url: process.env.BASE_URL + 'assets/images/sudoku/',
  },
  timeZone: 'Europe/Berlin',
}

export { settings }
