const menu = {
  buttons: [
    {
      name: 'home',
      icon: ['fad', 'home-heart'],
      href: 'hyperlinks.home.href',
      purpose: 'hyperlinks.home.purpose',
      new: false,
    },
    {
      name: 'academy',
      icon: ['fal', 'graduation-cap'],
      href: 'hyperlinks.academy.href',
      purpose: 'hyperlinks.academy.purpose',
      new: true,
    },
    {
      name: 'synapses',
      icon: ['fal', 'brain-circuit'],
      href: 'hyperlinks.synapses.href',
      purpose: 'hyperlinks.synapses.purpose',
      new: false,
    },
  ],
}

export { menu }
