<template>
  <v-col cols="0" md="5" lg="4" class="pr-3 pr-md-6">
    <div v-if="!$vuetify.breakpoint.smAndDown">
      <v-tabs v-model="tab" fixed-tabs>
        <v-tabs-slider color="blue-grey"></v-tabs-slider>
        <v-tab v-for="(item, index) in items" :key="`tab-${index}`" :class="[$theme.app.tabs.color]">
          <fa-icon :icon="['fal', item.icon]" size="lg" class="mr-2" />{{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-4" :class="[$theme.app.tabs.color]">
        <v-tab-item>
          <div class="mb-4">
            <h2>Sudoku Knacker informiert</h2>
            <v-hover v-slot="{ hover }">
              <Hyperlink :href="$t('hyperlinks.synapses.20240721.href')" :text-decoration="false">
                <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                  <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 21. Juli 2024</h3>
                  <div>Neuer Artikel „{{ $t('synapses.20240721.h1_title') }}“</div>
                </div>
              </Hyperlink>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <Hyperlink :href="$t('hyperlinks.synapses.20240623.href')" :text-decoration="false">
                <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                  <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 23. Juni 2024</h3>
                  <div>Neuer Artikel „{{ $t('synapses.20240623.h1_title') }}“</div>
                </div>
              </Hyperlink>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <Hyperlink :href="$t('hyperlinks.synapses.20240608.href')" :text-decoration="false">
                <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                  <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 8. Juni 2024</h3>
                  <div>Neuer Artikel „{{ $t('synapses.20240608.h1_title') }}“</div>
                </div>
              </Hyperlink>
            </v-hover>
            <template v-if="false">
              <v-hover v-slot="{ hover }">
                <Hyperlink :href="$t('hyperlinks.synapses.20240526.href')" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 26. Mai 2024</h3>
                    <div>Neuer Artikel „{{ $t('synapses.20240526.h1_title') }}“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink :href="$t('hyperlinks.synapses.20240516.href')" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 16. Mai 2024</h3>
                    <div>Neuer Artikel „{{ $t('synapses.20240516.h1_title') }}“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink :href="$t('hyperlinks.synapses.20240505.href')" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 5. Mai 2024</h3>
                    <div>Neuer Artikel „{{ $t('synapses.20240505.h1_title') }}“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink href="/de/akademie/lektionen/methoden/der-versteckte-einer" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? 'blue-grey lighten-5' : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'graduation-cap']" size="xs" class="mr-1" />Akademie - 1. Mai 2024</h3>
                    <div>Neue Lektion „Der Versteckte Einer“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink
                  href="/de/synapsen/2024/04/21/die-faszinierende-geschichte-des-sudoku-ursprung-und-globale-verbreitung"
                  :text-decoration="false"
                >
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 21. April 2024</h3>
                    <div>Neuer Artikel „{{ $t('synapses.20240421.h1_title') }}“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink
                  href="/de/synapsen/2024/04/11/ein-offenes-wort-warum-werbung-auf-sudoku-knacker-unverzichtbar-ist"
                  :text-decoration="false"
                >
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 11. April 2024</h3>
                    <div>Neuer Artikel „Ein offenes Wort: Warum Werbung auf Sudoku Knacker unverzichtbar ist”</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink href="/de/akademie/lektionen/methoden/das-full-house" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'graduation-cap']" size="xs" class="mr-1" />Akademie - 10. April 2024</h3>
                    <div>Neue Lektion „Das Full House“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink href="/de/synapsen/2024/04/02/willkommen-in-der-sudoku-knacker-akademie" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 2. April 2024</h3>
                    <div>Neuer Artikel „Deine Eintrittskarte in die Welt der Sudoku-Meister“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink href="/de/akademie/lektionen/methoden/der-nackte-einer" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'graduation-cap']" size="xs" class="mr-1" />Akademie - 2. April 2024</h3>
                    <div>Neue Lektion „Der Nackte Einer“</div>
                  </div>
                </Hyperlink>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <Hyperlink href="/de/synapsen/2024/03/26/entdecke-wie-sudoku-dich-geistig-fit-haelt" :text-decoration="false">
                  <div class="pa-2" :class="[hover ? $theme.app.marginalContent.hover.backgroundColor : '', $theme.app.tabs.color]">
                    <h3><fa-icon :icon="['fal', 'brain-circuit']" size="xs" class="mr-1" />Synapsen - 26. März 2024</h3>
                    <div>Neuer Artikel „Willkommen bei Synapsen: Dein regelmäßiges Sudoku-Brain-Gym!“</div>
                  </div>
                </Hyperlink>
              </v-hover>
            </template>
          </div>
        </v-tab-item>
        <v-tab-item>
          <login class="mb-4" />
        </v-tab-item>
      </v-tabs-items>
      <supporter></supporter>
      <black-friday-ad></black-friday-ad>
      <counter></counter>
      <template v-if="!isSupporter">
        <Adslot ad-unit="rectangle" ad-class="adslot__sidebar" class="mb-4" />
        <v-divider class="my-3" />
      </template>
      <appInfo />
      <daily-sudoku class="mb-6" />
    </div>
    <div v-else>
      <v-dialog v-model="showMarginalContentDialog" scrollable max-width="400">
        <template #activator="{ on, attrs }">
          <v-btn
            :color="$theme.app.navigation.buttons.open.color"
            class="marginal-content__btn"
            fab
            fixed
            :title="$t('components.buttons.btn_menu')"
            elevation="4"
            v-bind="attrs"
            v-on="on"
          >
            <fa-icon :class="$theme.app.navigation.buttons.open.text" size="2x" :icon="['fal', 'user-circle']"></fa-icon>
          </v-btn>
        </template>
        <v-card style="height: 100vh">
          <v-card-title class="pa-2 pb-0">
            <v-btn class="ml-auto" fab text @click="showMarginalContentDialog = false">
              <fa-icon :class="$theme.app.navigation.buttons.open.text" size="lg" :icon="['fal', 'times']"></fa-icon>
            </v-btn>
          </v-card-title>
          <v-card-text style="height: 300px">
            <login class="mb-6" />
            <supporter></supporter>
            <black-friday-ad></black-friday-ad>
            <counter></counter>
            <appInfo />
            <daily-sudoku class="mb-6" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-col>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import AppInfo from '@/components/app/Info.vue'
import BlackFridayAd from '@/components/black-friday/Ad'
import DailySudoku from '@/components/app/DailySudoku.vue'
import Hyperlink from '@/components/Hyperlink.vue'
import Login from '@/components/app/Login.vue'
import Counter from '@/components/app/Counter.vue'
import Supporter from '@/components/app/Supporter.vue'
import { theme } from '@/mixins'
import { mapGetters } from 'vuex'

export default {
  name: 'MarginalContent',
  components: {
    Adslot,
    AppInfo,
    BlackFridayAd,
    Counter,
    DailySudoku,
    Hyperlink,
    Login,
    Supporter,
  },
  mixins: [theme],
  data() {
    return {
      showMarginalContentDialog: false,
      tab: null,
      items: [
        { key: 'news', title: 'Aktuelles', icon: 'newspaper' },
        { key: 'account', title: 'Account', icon: 'circle-user' },
      ],
    }
  },
  computed: {
    ...mapGetters(['profile', 'isSupporter']),
  },
}
</script>

<style lang="scss">
.marginal-content {
  &__btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    right: -5px !important;
    top: 115px;
    width: 42px !important;
    height: 42px !important;
  }
  &__btn-avatar {
    width: 28px !important;
    height: 28px !important;
  }
  @media (min-width: 413px) {
    &__btn {
      width: 48px !important;
      height: 48px !important;
    }
    &__btn-avatar {
      width: 34px !important;
      height: 34px !important;
    }
  }
  @media (min-width: 600px) {
    &__btn {
      width: 52px !important;
      height: 52px !important;
    }
    &__btn-avatar {
      width: 40px !important;
      height: 40px !important;
    }
  }
}
</style>
