<template>
  <v-main
    id="component-app-body-main"
    class="app-body"
    :style="{
      'background-image': backgroundImage,
    }"
  >
    <v-container class="pa-0 pa-md-3">
      <navigation class="d-print-none" />
      <consent-mode />
      <v-card :class="$theme.app.body.color" elevation="0" class="rounded-lg">
        <v-container>
          <v-row class="d-print-none my-0">
            <v-col class="py-0">
              <AppHeader />
            </v-col>
          </v-row>
          <v-row v-if="!isSupporter" class="d-print-none my-0">
            <v-col>
              <Adslot ad-unit="leaderboard" ad-class="adslot__leaderboard" />
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col class="d-print-none text-left" :class="$theme.app.menu.backgroundColor">
              <v-btn
                v-for="(button, index) in buttons"
                :key="index"
                :href="$t(button.href)"
                :aria-label="$t(button.purpose)"
                :color="$vuetify.breakpoint.mdAndUp ? $theme.app.menu.buttonColor : 'transparent'"
                rounded
                exact
                elevation="0"
                :fab="!$vuetify.breakpoint.mdAndUp"
                class="mr-2"
              >
                <fa-icon
                  :class="{ 'mr-1': $vuetify.breakpoint.mdAndUp }"
                  :size="$vuetify.breakpoint.mdAndUp ? 'xl' : '2xl'"
                  :icon="button.icon"
                ></fa-icon>
                <i18n v-show="$vuetify.breakpoint.mdAndUp" class="mt-1 ml-1" :path="`components.app.menu.buttons.${button.name}`"></i18n>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="7" lg="8" class="app-body--main-content px-3 px-sm-6">
              <v-responsive :aspect-ratio="3 / 4">
                <Adslot ad-unit="content-zero" ad-class="adslot__content-zero" />
                <slot></slot>
              </v-responsive>
            </v-col>
            <marginal-content class="d-print-none" />
          </v-row>
          <v-row class="d-print-none mt-0 mb-2">
            <v-col class="pa-0">
              <AppFooter />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <Adslot
      v-if="!isSupporter && screenWidth > 1520"
      ad-unit="sticky-skyscraper"
      ad-class="adslot__sticky-skyscraper"
      class="d-print-none"
    />
    <SplashScreen></SplashScreen>
    <ComAlert class="d-print-none" />
  </v-main>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import AppFooter from '@/components/app/Footer.vue'
import AppHeader from '@/components/app/Header.vue'
import ComAlert from '@/components/ComAlert.vue'
import ConsentMode from '@/components/app/ConsentMode.vue'
import MarginalContent from '@/components/app/MarginalContent.vue'
import Navigation from '@/components/app/Navigation.vue'
import SplashScreen from '@/components/SplashScreen.vue'
import { lazy, responsive, theme, webp } from '@/mixins'
import { mapActions, mapGetters } from 'vuex'
import { settings, menu } from '@/config'
import suncalc from 'suncalc'

export default {
  name: 'AppBody',
  components: { MarginalContent, AppFooter, AppHeader, ComAlert, ConsentMode, Adslot, Navigation, SplashScreen },
  mixins: [responsive, lazy, theme, webp],
  computed: {
    ...mapGetters(['profile', 'getDaytime', 'suncalc', 'isSupporter']),
    backgroundImage() {
      if (this.isMounted && !['xs', 'sm'].includes(this.breakpoint)) {
        const appearance = this.$vuetify.theme.dark ? 'dark' : 'light'
        const image = this.webpExtension(`${settings.cdn.url}${this.bodyImageId}_${this.orientation}_${appearance}.jpg`)
        return `url(${image})`
      }
      return 'none'
    },
    bodyImageId() {
      return this.$theme.app.themes.ambience[this.getDaytime]
    },
    buttons() {
      return menu.buttons
    },
    screenWidth() {
      return this.$vssWidth
    },
  },
  async beforeMount() {
    await this.initSuncalc()
  },
  mounted() {
    this.setDaytime(this.daytime())
    setInterval(async () => {
      await this.initSuncalc()
      this.setDaytime(this.daytime())
    }, 30000)
  },
  methods: {
    ...mapActions(['setSuncalc', 'setDaytime']),
    async initSuncalc() {
      const today = new Date()
      if (this.suncalc.dawn === null || this.suncalc.dawn.getDate() !== today.getDate()) {
        let { dawn, sunrise, solarNoon, dusk, night, sunset } = suncalc.getTimes(new Date(), 50.94127, 6.9571)
        dawn = new Date(dawn.getTime() - 3600000)
        sunset = new Date(sunset.getTime() - 3600000)
        dusk = new Date(dusk.getTime() - 1200000)
        await this.setSuncalc({ dawn, sunrise, solarNoon, sunset, dusk, night })
      }
    },
    daytime() {
      const now = new Date()
      if (now > this.suncalc.night) return 'night'
      if (now > this.suncalc.dusk) return 'dusk'
      if (now > this.suncalc.sunset) return 'sunset'
      if (now > this.suncalc.solarNoon) return 'solarNoon'
      if (now > this.suncalc.sunrise) return 'sunrise'
      if (now > this.suncalc.dawn) return 'dawn'
      return 'night'
    },
  },
}
</script>

<style lang="scss">
.app-body {
  min-height: 100vh;
}
#component-app-body-main {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media print {
  .app-body {
    background-image: none !important;
    background-color: white !important;
    &--main-content {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
