export default {
  state: {
    show: false,
    icon: {
      prefix: 'fal',
      name: 'exclamation-triangle',
    },
    title: 'Error',
    text: 'Internal Error',
  },
  mutations: {
    HIDE_COM_ALERT(state) {
      state.show = false
    },
    SHOW_COM_ALERT(state, payload) {
      state.title = payload.title || 'Error'
      state.text = payload.text || 'Internal Error'
      if (typeof payload.icon !== 'undefined') {
        state.icon.prefix = payload.icon.prefix || 'fal'
        state.icon.name = payload.icon.name || 'Internal Server Error'
      }
      state.show = true
    },
  },
  actions: {
    hideComAlert({ commit }) {
      commit('HIDE_COM_ALERT')
    },
    showComAlert({ commit }, payload) {
      commit('SHOW_COM_ALERT', payload)
    },
  },
  modules: {},
}
