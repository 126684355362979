export default {
  state: {
    payment: {
      show: true,
    },
  },
  mutations: {
    SET_PAYMENT(state, val) {
      state.payment.show = val
    },
  },
  actions: {
    showPayment({ commit }) {
      commit('SET_PAYMENT', true)
    },
    hidePayment({ commit }) {
      commit('SET_PAYMENT', false)
    },
  },
  modules: {},
}
