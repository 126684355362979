const webp = {
  // Sourc Information: https://dev.to/gksander/webp-ing-your-site-reduce-image-file-size-increase-site-performance-4ho8
  data: () => {
    return {
      webpSupported: true,
    }
  },
  created() {
    ;(async () => {
      if (!self.createImageBitmap) {
        this.webpSupported = false
        return
      }

      const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA='
      const blob = await fetch(webpData).then((r) => r.blob())
      this.webpSupported = await createImageBitmap(blob).then(
        () => true,
        () => false
      )
    })()
  },
  methods: {
    webpExtension(filename) {
      if (this.webpSupported) {
        return filename.replace(/\.\w{1,5}$/, '.webp')
      } else {
        return filename
      }
    },
  },
}

export { webp }
