<template>
  <div class="d-inline" :class="!textDecoration ? 'hyperlink-no-text-decoration' : ''">
    <a :href="href" :aria-label="ariaLabel" :target="target" :rel="rel" @click.prevent="go()">
      <slot></slot>
    </a>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Hyperlink',
  props: {
    href: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    rel: {
      type: String,
      default: '',
    },
    textDecoration: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapActions(['showLoader', 'hideLoader', 'hideNavigation', 'setPurpose']),
    go() {
      this.hideNavigation()
      this.setPurpose(this.ariaLabel)
      this.showLoader()
      setTimeout(() => {
        window.open(this.href, this.target).then(() => this.hideLoader())
      }, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.hyperlink-no-text-decoration {
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
}
</style>
