const adsense = {
  name: 'adsense',
  percentage: 0,
  adClient: 'ca-pub-7786462965305382',
  adUnits: {
    leaderboard: {
      adSlot: '5382480231',
      adFormat: 'auto',
    },
    rectangle: {
      adSlot: '6085187816',
      adFormat: 'auto',
    },
    'solved-rectangle': {
      adSlot: '6972308745',
      adFormat: 'auto',
    },
    'sticky-skyscraper': {
      adSlot: '7407399961',
      adFormat: 'auto',
    },
  },
}

const ezoic = {
  name: 'ezoic',
  percentage: 100,
  adUnits: {
    leaderboard: {
      adSlot: '103',
    },
    'sticky-skyscraper': {
      adSlot: '104',
    },
    rectangle: {
      adSlot: '105',
    },
    'solved-rectangle': {
      adSlot: '106',
    },
    'content-zero': {
      adSlot: '116',
    },
    'content-first': {
      adSlot: '107',
    },
    'content-last': {
      adSlot: '108',
    },
    'content-second': {
      adSlot: '109',
    },
    'content-third': {
      adSlot: '114',
    },
    'content-fourth': {
      adSlot: '115',
    },
    footer: {
      adSlot: '113',
    },
  },
}

export { adsense, ezoic }
