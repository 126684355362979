<template>
  <div class="d-print-none">
    <Navbar />
    <header
      id="component-app-header-header"
      class="header mx-n3"
      :class="headerClass"
      :style="{
        'background-image': backgroundImage,
      }"
    >
      <div id="component-app-header-title">
        <hyperlink :href="$t('hyperlinks.home.href')" :aria-label="$t('hyperlinks.home.purpose')">
          <Title class="title mx-auto mx-sm-0" />
        </hyperlink>
      </div>
    </header>
  </div>
</template>

<script>
import Hyperlink from '@/components/Hyperlink.vue'
import Navbar from '@/components/Navbar'
import Title from '@/components/svg/Title.vue'
import { lazy, responsive, webp, theme } from '@/mixins'
import { settings } from '@/config'
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',
  components: { Hyperlink, Navbar, Title },
  mixins: [lazy, responsive, theme, webp],
  inject: ['theme'],
  computed: {
    ...mapGetters(['getDaytime']),
    headerClass() {
      const theme = !this.theme.isDark ? 'light' : 'dark'
      return `header-${this.size()} ${theme}`
    },
    headerImageId() {
      return this.$theme.app.themes.ambience[this.getDaytime]
    },
    backgroundImage() {
      const imageSize = ['lg', 'xl'].includes(this.size()) ? 'lg' : 'md'
      const image = this.webpExtension(`${settings.cdn.url}${this.headerImageId}_${imageSize}_header.jpg`)
      return `url(${image})`
    },
  },
  methods: {
    size() {
      if (this.breakpoint === 'xl') {
        return 'lg'
      }
      return this.breakpoint
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  background-size: cover;
  height: calc(100vw / 5.73);
}
.light #component-app-header-title {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark #component-app-header-title {
  background-color: rgba(0, 0, 0, 0.25);
}
.header-xs {
  #component-app-header-title {
    background-color: rgba(255, 255, 255, 0);
    .title {
      position: absolute;
      left: calc(50vw - 148px);
      top: calc((100vw / 5.73) / 2 - 2px);
      width: 296px;
    }
  }
}
.header-sm {
  padding: calc(100vw / 21.3) 0;
  #component-app-header-title {
    padding: 12px 2vw 1px 2vw;
    width: 50vw;
    .title {
      max-width: 40vw;
    }
  }
}
.header-md {
  height: 153px;
  padding: 42px 0;
  #component-app-header-title {
    padding: 12px 21px 1px 21px;
    width: 438px;
    .title {
      max-width: 400px;
    }
  }
}
.header-lg {
  height: 208px;
  padding: 70px 0;
  #component-app-header-title {
    padding: 12px 24px 1px 24px;
    width: 580px;
    .title {
      max-width: 387px;
    }
  }
}
.header-xl {
  height: 308px;
  padding: 100px 0;
  #component-app-header-title {
    padding: 20px 36px 9px 36px;
    width: 880px;
    .title {
      max-width: 587px;
    }
  }
}
</style>
