import axios from 'axios'
import qs from 'querystring'
import Vue from 'vue'
import store from '@/store'

class Com {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_BASE_URL
    this.accept = 'application/json'
    this.vue = new Vue()
  }

  authorization() {
    return this.vue.$cookies.isKey('authenticationToken') ? `Bearer ${this.vue.$cookies.get('authenticationToken')}` : null
  }

  async delete(options) {
    await axios({
      method: 'delete',
      url: options.url,
      headers: {
        ...options.headers,
        Accept: this.accept,
      },
    }).catch((err) => {
      throw err
    })
  }
  async get(options) {
    const queryParam = typeof options.queryParam !== 'undefined' ? `?${qs.stringify(options.queryParam)}` : ''
    return await axios({
      method: 'get',
      url: options.url + queryParam,
      headers: {
        ...options.headers,
        Accept: this.accept,
      },
    }).catch((err) => {
      throw err
    })
  }
  async post(options) {
    return await axios({
      method: 'post',
      url: options.url,
      headers: {
        ...options.headers,
        Accept: this.accept,
      },
      data: qs.stringify(options.requestBody),
    }).catch((err) => {
      throw err
    })
  }
  async alert(error) {
    if (typeof error.response !== 'undefined') {
      const text = `HTTP-Status ${error.response.status} (${error.response.statusText}) - ${error.response.data.message}`
      console.log(`#/> ${text}`)
      // return await store.dispatch('showComAlert', { text })
    } else {
      const text = error.message
      console.log(`#/> ${text}`)
      // return await store.dispatch('showComAlert', { text })
    }
  }
  async authenticate(param) {
    try {
      return await this.post({
        url: this.url('/api/authenticate'),
        requestBody: { ...param },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async cleanupAuthentication() {
    await store.dispatch('setAuthenticationExpires', 0).then(() => this.vue.$cookies.remove('authenticationToken'))
  }
  async createApprovedPayment(amount, period, email, paymentId) {
    try {
      return await this.post({
        url: this.url('/api/approved-payment'),
        headers: { Authorization: this.authorization() },
        requestBody: { amount, period, email, paymentId },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async deleteSavedPuzzle(puzzleId) {
    try {
      await this.delete({
        url: this.url(`/api/sudoku/delete-saved-puzzle/${parseInt(puzzleId)}`),
        headers: { Authorization: this.authorization() },
      })
      return true
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getCaptcha() {
    const ipAddress = await this.getIpAddress()
    try {
      return await this.get({ url: this.url('/api/captcha'), queryParam: { ipAddress: ipAddress.ipAddress } })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getDailySudoku(level) {
    try {
      return await this.get({
        url: this.url(`/api/daily-sudoku/${level}`),
        headers: { Authorization: this.authorization() },
        queryParam: { timeStamp: Date.now() },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getDailySudokuStatistics() {
    try {
      return await this.get({
        url: this.url('/api/daily-sudoku/statistics'),
        headers: { Authorization: this.authorization() },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getDailySudokuWinners(requestBody) {
    try {
      return await this.post({
        url: this.url('/api/daily-sudoku/winners'),
        requestBody,
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getIpAddress() {
    try {
      const response = await this.get({ url: this.url('/api/ip-address') })
      return response.data
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getLastSavedPuzzles() {
    try {
      return await this.post({
        url: this.url('/api/sudoku/saved-puzzles'),
        headers: { Authorization: this.authorization() },
        requestBody: { page: 1, itemsPerPage: 5, sortBy: ['updatedAt'], sortDesc: [true], solved: false },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getProfile() {
    try {
      return await this.get({
        url: this.url('/api/profile'),
        headers: { Authorization: this.authorization() },
      })
    } catch (error) {
      await this.cleanupAuthentication()
      await this.alert(error)
      return false
    }
  }
  async getRecentSavegame() {
    try {
      return await this.get({
        url: this.url('/api/sudoku/recent'),
        headers: { Authorization: this.authorization() },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getSavedPuzzles(requestBody) {
    try {
      return await this.post({
        url: this.url('/api/sudoku/saved-puzzles'),
        headers: { Authorization: this.authorization() },
        requestBody,
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getSudokuById(sudokuId) {
    const Authorization = this.authorization()
    const headers = Authorization !== null ? { Authorization } : {}

    try {
      return await this.get({ url: this.url('/api/sudoku'), headers, queryParam: { sudokuId } })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getSudokuByLevel(level) {
    const Authorization = this.authorization()
    const headers = Authorization !== null ? { Authorization } : {}

    try {
      return await this.get({
        url: this.url(`/api/sudoku/stars/${level}`),
        headers,
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async getSudokuStatistics(requestBody) {
    try {
      return await this.get({
        url: this.url('/api/sudoku/statistics'),
        headers: { Authorization: this.authorization() },
        requestBody,
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async login(requestBody) {
    try {
      return await this.post({
        url: this.url('/api/login'),
        requestBody,
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async logout() {
    try {
      await this.delete({
        url: this.url('/api/logout'),
        headers: { Authorization: this.authorization() },
      })
      await this.cleanupAuthentication()
      return true
    } catch (error) {
      await this.cleanupAuthentication()
      await this.alert(error)
      return false
    }
  }
  async sendContact(requestBody) {
    try {
      return await this.post({
        url: this.url('/api/contact'),
        requestBody,
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async updateCounter() {
    const cookies = this.vue.$cookies
    const counterUUID = cookies.isKey('counterUUID') ? cookies.get('counterUUID') : 'none'
    try {
      const response = await this.post({ url: this.url('/api/counter'), requestBody: { counterUUID } })
      const counter = response.data
      await store.dispatch('setCounter', { online: counter.online, today: counter.today, yesterday: counter.yesterday })
      cookies.set('counterUUID', counter.counterUUID, '2h')
      return counter
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async updateDailySudokuAgreement(agreement) {
    try {
      return await this.post({
        url: this.url('/api/daily-sudoku/agreement'),
        headers: { Authorization: this.authorization() },
        requestBody: { agreement },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async updateDailySudokuSavegame(savegame, progress) {
    try {
      return await this.post({
        url: this.url('/api/daily-sudoku/save'),
        headers: { Authorization: this.authorization() },
        requestBody: { savegame, progress },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }
  async updateProfile(userName, userInfo, userAvatar) {
    try {
      return await this.post({
        url: this.url('/api/profile'),
        headers: { Authorization: this.authorization() },
        requestBody: { userName, userInfo, userAvatar },
      })
    } catch (error) {
      await this.cleanupAuthentication()
      await this.alert(error)
      return false
    }
  }
  async updateSavegame(puzzleId, savegame, progress) {
    try {
      return await this.post({
        url: this.url('/api/sudoku/save'),
        headers: { Authorization: this.authorization() },
        requestBody: { puzzleId, savegame, progress },
      })
    } catch (error) {
      await this.alert(error)
      return false
    }
  }

  url(path) {
    return this.baseUrl + path
  }
}

export { Com }
