<template>
  <v-overlay class="d-print-none" :value="loader.loader.show">
    <div class="text-center">
      <v-progress-circular indeterminate size="64" />
    </div>
    <div class="text-center mt-3">
      <strong>{{ loader.loader.purpose }}</strong>
    </div>
  </v-overlay>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Loader',
  inject: ['theme'],
  data: () => {
    return {}
  },
  computed: {
    ...mapState(['loader']),
  },
  created() {
    this.showLoader()
  },
  methods: {
    ...mapActions(['showLoader']),
  },
}
</script>

<style lang="scss" scoped>
#component-loader-progress-linear {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
}
</style>
