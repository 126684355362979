export default {
  state: {
    suncalc: {
      dawn: null,
      sunrise: null,
      solarNoon: null,
      sunsetStart: null,
      dusk: null,
      night: null,
    },
    daytime: 'night',
  },
  getters: {
    getDaytime(state) {
      return state.daytime
    },
    suncalc(state) {
      return state.suncalc
    },
  },
  mutations: {
    SET_SUNCALC(state, payload) {
      state.suncalc = payload
    },
    SET_DAYTIME(state, val) {
      state.daytime = val
    },
  },
  actions: {
    setDaytime({ commit }, val) {
      commit('SET_DAYTIME', val)
    },
    setSuncalc({ commit }, payload) {
      commit('SET_SUNCALC', payload)
    },
  },
  modules: {},
}
