import { themes } from '@/config/index.js'

const theme = {
  inject: ['theme'],
  computed: {
    $theme() {
      return this.theme.isDark ? themes.default.dark : themes.default.light
    },
  },
}

export { theme }
