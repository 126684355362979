const blackFriday = {
  methods: {
    isBlackFriday() {
      const today = new Date()
      const currentYear = today.getFullYear()
      const blackFriday = new Date(currentYear, 10, 29 - new Date(currentYear, 8).getDay())
      return today.setHours(0, 0, 0, 0) === blackFriday.setHours(0, 0, 0, 0)
    },
  },
}

export { blackFriday }
