<template>
  <div>
    <v-bottom-sheet v-model="comAlert.show" persistent>
      <v-sheet class="com-alert__sheet white">
        <v-container class="overflow-y-auto" style="max-height: 75vh">
          <v-row class="px-3 my-0">
            <v-col class="red--text text--darken-2">
              <v-btn small fab color="transparent" elevation="0" class="float-right" @click="hideComAlert()">
                <fa-icon class="grey--text" :icon="['fal', 'times']" size="2x" />
              </v-btn>

              <v-card class="grey lighten-3 mt-12 mb-3">
                <v-card-title class="red--text text--darken-3">
                  <fa-layers class="mx-2">
                    <fa-icon :icon="[comAlert.icon.prefix, comAlert.icon.name]" size="sm" />
                  </fa-layers>
                  {{ comAlert.title }}
                </v-card-title>
                <v-card-subtitle>
                  <code class="transparent red--text text--darken-3">{{ comAlert.text }}</code>
                </v-card-subtitle>
                <v-card-text class="text-body-1 font-weight-bold mt-3 mb-n1">
                  <div class="grey--text text--darken-3 white py-3 px-4 rounded-lg">
                    <p class="mb-0">
                      {{ $t('components.com_alert.p_text_1') }}
                    </p>
                    <p class="mb-0">
                      <i18n path="components.com_alert.p_text_2">
                        <template #mailto>
                          <a href="mailto:support@sudoku-knacker.de">support@sudoku-knacker.de</a>
                        </template>
                      </i18n>
                    </p>
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-list-item>
                    <v-list-item-avatar class="blue-grey lighten-4">
                      <v-img
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Brown&facialHairType=Blank&clotheType=Hoodie&clotheColor=Black&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="grey--text text--darken-3">Viele Grüße<br />Marco</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ComAlert',
  data() {
    return {
      show: true,
    }
  },
  computed: {
    ...mapState(['comAlert']),
  },
  methods: {
    ...mapActions(['hideComAlert']),
  },
}
</script>

<style lang="scss"></style>
