<template>
  <div>
    <h2>Anmelden</h2>
    <div v-if="waitForPin || page === 2">
      <login-processed :email="email" @reset="back()" />
    </div>
    <div v-else>
      <form novalidate @submit.prevent="submit()">
        <v-row class="my-0">
          <v-col class="py-0">
            <v-text-field
              id="email"
              v-model="email"
              :disabled="disableFormElements"
              :error-messages="emailErrors"
              :label="$t('components.app.login.input_email')"
              color="teal"
              name="email"
              type="email"
              placeholder=" "
              :data-claim="email"
              maxlength="128"
              @input="emailInput()"
              @blur="emailInput()"
            >
            </v-text-field>
            <div class="text-caption mb-3">
              <v-simple-checkbox
                v-model="newPassword"
                :disabled="disableFormElements"
                color="blue-grey"
                class="d-inline mr-1"
                :ripple="false"
              ></v-simple-checkbox
              >{{ $t('components.app.login.chk_new_password') }}
            </div>
            <v-text-field
              id="password"
              v-model="password"
              :disabled="disableFormElements"
              :error-messages="passwordErrors"
              :label="newPassword ? $t('components.app.login.input_new_password') : $t('components.app.login.input_password')"
              color="teal"
              name="password"
              type="password"
              placeholder=" "
              maxlength="1024"
              @input="passwordInput()"
              @blur="passwordInput()"
            ></v-text-field>
            <div v-if="newPassword">
              <v-row class="my-0">
                <v-col class="text-center pt-0">
                  <img
                    v-if="captchaImage !== null"
                    :src="captchaImage"
                    :class="$theme.app.login.verificationCode.backgroundColor"
                    :alt="$t('components.app.login.img_captcha')"
                  />
                  <div v-else class="pt-2">
                    <fa-icon size="lg" :icon="['fad', 'spinner']" spin></fa-icon>
                  </div>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    id="captcha"
                    v-model="captcha"
                    class="mt-0"
                    :disabled="disableFormElements"
                    :error-messages="captchaErrors"
                    label="Sicherheitscode"
                    color="teal"
                    @input="captchaInput()"
                    @blur="captchaInput()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col class="py-0">
                  <v-alert v-if="captchaError" outlined type="error" border="left">{{
                    $t('components.app.login.alert_captcha_validation')
                  }}</v-alert>
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col class="py-0">
                  <p class="login__caption caption">
                    <i18n path="components.app.login.p_create_new_verification_code">
                      <template #here>
                        <a href="#" @click.prevent="reloadCaptcha()">{{
                          $t('components.app.login.p_create_new_verification_code_here')
                        }}</a>
                      </template>
                    </i18n>
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="py-0">
            <v-alert v-if="authenticationError" outlined type="error" border="left">{{
              $t('components.app.login.alert_wrong_password')
            }}</v-alert>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col class="py-0">
            <div class="text-center mt-1 mb-4">
              <v-btn :disabled="disableFormElements" color="$theme.btn.color" outlined rounded type="submit"
                ><fa-icon class="mr-2" size="lg" :icon="['fal', 'paper-plane']" />{{ $t('components.app.login.btn_login') }}</v-btn
              >
            </div>
            <p class="login__caption text-caption">
              <i18n path="components.app.login.p_caption">
                <template #terms-of-use>
                  <hyperlink :href="$t('hyperlinks.terms.href')" :aria-label="$t('hyperlinks.terms.purpose')">{{
                    $t('components.app.login.a_terms')
                  }}</hyperlink>
                </template>
              </i18n>
            </p>
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import Hyperlink from '@/components/Hyperlink.vue'
import LoginProcessed from '@/components/LoginProcessed'
import { email, required, minLength } from 'vuelidate/lib/validators'
import { password } from '@/util'
import { theme } from '@/mixins'
import { validationMixin } from 'vuelidate'
import { Com } from '@/util'
import jwt_decode from 'jwt-decode'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginForm',
  components: { Hyperlink, LoginProcessed },
  mixins: [validationMixin, theme],
  data() {
    return {
      authenticationError: false,
      captcha: '',
      captchaError: false,
      captchaImage: null,
      captchaKey: null,
      captchaReloadCounter: 0,
      disableFormElements: false,
      email: '',
      newPassword: false,
      page: 1,
      password: '',
    }
  },
  validations: {
    captcha: { required },
    email: { required, email },
    password: { required, minLength: minLength(8), password },
  },
  computed: {
    ...mapGetters(['user']),
    captchaErrors() {
      const errors = []
      if (!this.$v.captcha.$dirty) return errors
      !this.$v.captcha.required && errors.push(this.$t('vuelidate.error.required'))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$t('vuelidate.error.email'))
      !this.$v.email.required && errors.push(this.$t('vuelidate.error.required'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t('vuelidate.error.required'))
      !this.$v.password.minLength && errors.push(this.$t('vuelidate.error.password_min_length'))
      !this.$v.password.password && errors.push(this.$t('vuelidate.error.password'))
      return errors
    },
    waitForPin() {
      return this.user.emailSent.exp || this.user.emailSent.exp < this.currentTimestamp
    },
  },
  watch: {
    newPassword(val) {
      this.password = ''
      this.captcha = ''
      this.selectPasswordInput()
      if (val) this.captchaKey === null && this.getCaptcha()
      this.captchaInput()
    },
  },
  beforeMount() {
    const emailSentExpires = this.$cookies.isKey('emailSent') ? this.$cookies.get('emailSent') : 0
    this.setEmailSentExpires(emailSentExpires)
  },
  methods: {
    ...mapActions(['hideLoader', 'showLoader', 'setPurpose', 'setAuthenticationExpires', 'setEmailSentExpires', 'setProfile']),
    async getCaptcha() {
      const com = new Com()
      setTimeout(async () => {
        const response = await com.getCaptcha()
        if (response) {
          const captchaBase64Encoded = window.btoa(response.data.svg)
          this.captchaImage = `data:image/svg+xml;base64,${captchaBase64Encoded}`
          this.captchaKey = response.data.key
        }
      }, 1000 * this.captchaReloadCounter++)
    },
    async submit() {
      this.disableFormElements = true
      this.$v.$touch()
      if (!(this.$v.email.$invalid || this.$v.password.$invalid)) {
        if (!this.newPassword) {
          this.setPurpose(this.$t('components.app.login.btn_login_purpose'))
          this.showLoader()
          const com = new Com()
          const { email, password } = this
          const response = await com.authenticate({ login: 'password', email, password })
          if (typeof response === 'object') {
            if (response.data.success === true) {
              setTimeout(async () => {
                const authenticationToken = response.data.authenticationToken
                const authenticationTokenDecoded = jwt_decode(authenticationToken)
                this.setAuthenticationExpires(authenticationTokenDecoded.exp)
                this.$cookies.set('authenticationToken', authenticationToken, '365d')
                location.reload()
              }, 1)
            } else {
              this.authenticationError = true
              this.hideLoader()

              this.selectPasswordInput()
            }
          }
        } else {
          const com = new Com()
          this.setPurpose(this.$t('components.app.login.get_public_ip_address'))
          this.showLoader()
          const ipAddress = await com.getIpAddress()
          this.setPurpose(this.$t('components.app.login.btn_login_purpose'))
          const requestBody = {
            email: this.email.toLowerCase(),
            password: this.password,
            captchaValue: this.captcha.toLowerCase(),
            captchaKey: this.captchaKey,
            ipAddress: ipAddress.ipAddress,
            redirectScheme: process.env.VUE_APP_REDIRECT_SCHEME,
            redirectAuthority: process.env.VUE_APP_REDIRECT_AUTHORITY,
            redirectPath: this.$t('hyperlinks.authentication.href'),
          }
          const response = await com.login(requestBody)
          if (typeof response === 'object') {
            if (response.data.success === true) {
              const loginToken = response.data.loginToken
              const expTimestamp = Math.floor(Date.now() / 1000 + 900)
              this.setEmailSentExpires(expTimestamp)
              this.$cookies.set('emailSent', expTimestamp, '900s')
              this.$cookies.set('loginToken', loginToken, '900s')
              this.email = ''
              this.captchaImage = null
              this.captcha = ''
              this.captchaIsVisible = false
              this.$v.email.$reset()
              this.page = 2
            } else {
              this.reloadCaptcha()
              this.captchaError = true
              this.focusCaptchaInput()
            }
          }
          this.hideLoader()
        }
      }
      this.disableFormElements = false
    },
    captchaInput() {
      this.captcha = this.captcha.toLowerCase()
      this.captchaError = false
      this.$v.captcha.$reset()
    },
    emailInput() {
      this.email = this.email.toLowerCase()
      this.$v.email.$reset()
    },
    selectEmailInput() {
      setTimeout(async () => {
        document.getElementById('email').focus()
        document.getElementById('email').select()
      }, 1)
    },
    selectPasswordInput() {
      this.$v.password.$reset()
      setTimeout(async () => {
        document.getElementById('password').focus()
        document.getElementById('password').select()
      }, 1)
    },
    focusCaptchaInput() {
      setTimeout(async () => {
        document.getElementById('captcha').focus()
      }, 1)
    },
    passwordInput() {
      this.authenticationError = false
      this.$v.password.$reset()
    },
    reloadCaptcha() {
      if (this.captchaImage != null) {
        this.captchaImage = null
        this.captcha = ''
        this.getCaptcha()
      }
    },
    back() {
      this.page = 1
      this.email = ''
      this.password = ''
      this.newPassword = false
      setTimeout(async () => {
        this.selectEmailInput()
      }, 1)
    },
  },
}
</script>

<style lang="scss"></style>
