export default {
  state: {
    emailSent: {
      exp: 0,
    },
    authentication: {
      exp: 0,
    },
    dailySudoku: {
      termsAccepted: false,
    },
    profile: {
      userId: null,
      userName: '',
      userInfo: '',
      userAvatar: null,
      userSupporter: null,
      userDailySudokuAgreement: null,
    },
  },
  getters: {
    user(state) {
      return state
    },
    profile(state) {
      return state.profile
    },
    actualPeriod(state) {
      if (state.profile.userSupporter === null) return null
      let date = new Date(state.profile.userSupporter)
      date.setHours(23, 59, 59)
      return date
    },
    isSupporter(state) {
      let now = new Date()
      now.setHours(0, 0, 0, 0)
      return state.profile.userSupporter !== null && state.profile.userSupporter > now.getTime()
    },
  },
  mutations: {
    SET_EMAIL_SENT_EXP(state, exp) {
      state.emailSent.exp = exp
    },
    SET_AUTHENTICATION_EXP(state, exp) {
      state.authentication.exp = exp
    },
    SET_DAILY_SUDOKU_TERMS_ACCEPTED(state, val) {
      state.dailySudoku.termsAccepted = val
    },
    SET_PROFILE(state, payload) {
      state.profile = {
        ...state.profile,
        ...payload,
      }
    },
  },
  actions: {
    setDailySudokuTermsAccepted({ commit }, val) {
      commit('SET_DAILY_SUDOKU_TERMS_ACCEPTED', val)
    },
    setEmailSentExpires({ commit }, exp) {
      commit('SET_EMAIL_SENT_EXP', exp)
    },
    setAuthenticationExpires({ commit }, exp) {
      commit('SET_AUTHENTICATION_EXP', exp)
    },
    setProfile({ commit }, payload) {
      if (typeof payload.userSupporter === 'undefined' || payload.userSupporter === null) {
        commit('SET_PROFILE', payload)
      } else {
        let userSupporter = new Date(payload.userSupporter)
        userSupporter.setHours(23, 59, 59)
        commit('SET_PROFILE', { ...payload, userSupporter: userSupporter.getTime() })
      }
    },
  },
  modules: {},
}
