<template>
  <div class="d-print-none">
    <!-- START // Navigation Button -->
    <v-btn
      v-show="!navigation.show"
      class="navigation__btn"
      :color="$theme.app.navigation.buttons.open.color"
      fab
      fixed
      :title="$t('components.buttons.btn_menu')"
      elevation="4"
      @click="showNavigation()"
    >
      <fa-icon :class="$theme.app.navigation.buttons.open.text" size="lg" transform="grow-3" :icon="['far', 'bars']"></fa-icon>
    </v-btn>
    <!-- END // Navigation Button -->

    <v-navigation-drawer v-model="navigation.show" temporary app>
      <!-- START // Hide Navigation Button -->
      <div class="text-right">
        <v-btn class="ma-2" :color="$theme.app.navigation.buttons.close.color" elevation="0" small fab @click="hideNavigation()">
          <fa-icon :class="$theme.app.navigation.buttons.close.text" size="lg" :icon="['fal', 'times']"></fa-icon>
        </v-btn>
      </div>
      <!-- END // Hide Navigation Button -->

      <v-sheet class="mb-16">
        <v-divider class="mb-3" />

        <!-- START // Navigation General -->
        <h3 class="mx-3">{{ $t('components.app.navigation.h4_general') }}</h3>
        <v-list class="py-0">
          <v-list-item-group color="primary">
            <div class="navigation__hyperlink">
              <a href="#" :aria-label="$t('components.app.navigation.list_item_print_aria_label')" @click.prevent="print()">
                <v-list-item>
                  <v-list-item-icon class="mr-1">
                    <fa-icon class="fa-fw" :icon="['fad', 'print']"></fa-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t('components.app.navigation.list_item_print')"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </a>
              <Hyperlink :href="$t('hyperlinks.release_notes.href')" :aria-label="$t('hyperlinks.release_notes.purpose')">
                <v-list-item>
                  <v-list-item-icon class="mr-1">
                    <fa-icon class="fa-fw" :icon="['fad', 'list-ul']"></fa-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t('components.app.navigation.list_item_release_notes')"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </Hyperlink>
            </div>
          </v-list-item-group>
        </v-list>
        <!-- END // Navigation General -->

        <v-divider class="my-3" />

        <!-- START // Navigation Daily Sudoku -->
        <h3 class="mx-3">{{ $t('components.app.navigation.h3_daily_sudoku') }}</h3>
        <v-list class="py-0">
          <v-list-item-group color="secondary">
            <v-list-item @click="gotoDailySudoku()">
              <v-list-item-icon class="mr-1">
                <fa-layers class="pa-0">
                  <fa-icon class="white--text" :icon="['fas', 'circle']" transform="grow-3" />
                  <fa-icon v-if="getSolvedToday" class="green--text" :icon="['fas', 'check-circle']" transform="grow-3"></fa-icon>
                  <fa-icon v-else class="grey--text text--lighten-1" :icon="['fas', 'times-circle']" transform="grow-3"></fa-icon>
                </fa-layers>
              </v-list-item-icon>
              <v-list-item-content>
                {{ currentDate }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="gotoDailySudokuWinners()">
              <v-list-item-icon class="mr-1">
                <fa-layers class="pa-0">
                  <fa-icon class="yellow--text text--darken-2" :icon="['fad', 'medal']" transform="grow-3" />
                </fa-layers>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t('components.app.navigation.list_previous_winners') }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <!-- END // Navigation Daily Sudoku -->

        <v-divider class="my-3" />
        <h3 class="mx-3">{{ $t('globals.sudoku') }}</h3>
        <v-list class="py-0">
          <v-list-item-group color="secondary">
            <v-list-item @click="gotoSavedPuzzles()">
              <v-list-item-icon class="mr-1">
                <fa-icon class="blue--text text--lighten-3" :icon="['far', 'cloud']" transform="grow-3"></fa-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t('views.saved_puzzles.h1_title') }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="gotoSolvedPuzzles()">
              <v-list-item-icon class="mr-1">
                <fa-icon class="green--text" :icon="['far', 'check']" transform="grow-3"></fa-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t('views.solved_puzzles.h1_title') }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-expansion-panels v-model="panel" accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-3">
              <h4>{{ $t('components.app.navigation.h4_new_puzzle') }}</h4>
              <template #actions>
                <fa-icon class="teal--text fa-fw" :icon="['far', panel === 0 ? 'minus-circle' : 'plus-circle']" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-n6">
              <v-list class="py-0">
                <v-list-item-group color="primary">
                  <hyperlink
                    v-for="index in 5"
                    :key="index"
                    class="navigation__hyperlink"
                    :href="getHrefByLevel(6 - index)"
                    :aria-label="getPurposeByLevel(6 - index)"
                  >
                    <v-list-item>
                      <v-list-item-icon class="mr-1">
                        <fa-icon class="yellow--text" :icon="['fas', 'star']" transform="grow-6"></fa-icon>
                        <div class="ml-n3 text-caption grey--text text--darken-3">{{ 6 - index }}</div>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="$t(`globals.level.level_${6 - index}`)"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </hyperlink>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-3">
              <h4>{{ $t('components.app.navigation.h4_last_puzzles') }}</h4>
              <template #actions>
                <fa-icon class="teal--text fa-fw" :icon="['far', panel === 1 ? 'minus-circle' : 'plus-circle']" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mx-n6">
              <v-list v-if="isAuthenticated" class="py-0">
                <v-list-item-group color="primary">
                  <hyperlink
                    v-for="(puzzle, index) in lastSavedPuzzles"
                    :key="index"
                    class="navigation__hyperlink"
                    :href="getHrefByPuzzleId(puzzle.puzzleId)"
                    :aria-label="getPurposeById(puzzle.puzzleId)"
                  >
                    <v-list-item>
                      <v-list-item-icon class="mr-1">
                        <fa-icon class="yellow--text" :icon="['fas', 'star']" transform="grow-6"></fa-icon>
                        <div class="ml-n3 text-caption grey--text text--darken-3">{{ puzzle.stars }}</div>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="`Rätsel ${formatNumber(puzzle.puzzleId)}`"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-progress-linear :value="puzzle.progress" color="teal lighten-3" height="21" class="" style="width: 55px">
                          <strong>{{ puzzle.progress }} %</strong>
                        </v-progress-linear>
                      </v-list-item-action>
                    </v-list-item>
                  </hyperlink>
                </v-list-item-group>
              </v-list>
              <p v-else class="px-3 text--caption">{{ $t('components.app.navigation.recent_puzzles.p_not_authenticated') }}</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mt-6 mb-3" />
        <h3 class="mx-3">{{ $t('components.app.navigation.h3_settings') }}</h3>
        <v-list class="py-0">
          <v-list-item-group color="secondary">
            <!-- Toggle Theme -->
            <v-list-item @click="toggleTheme">
              <v-list-item-icon class="mr-1">
                <fa-icon
                  v-if="$vuetify.theme.dark"
                  class="blue-grey--text text--lighten-4 fa-fw"
                  :icon="['fad', 'lightbulb']"
                  transform="grow-3"
                ></fa-icon>
                <fa-icon v-else class="yellow--text text--darken-3 fa-fw" :icon="['fad', 'lightbulb-on']" transform="grow-3"></fa-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t('components.app.navigation.list_item_appearance') }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Hyperlink from '@/components/Hyperlink.vue'
import { Com } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import { theme } from '@/mixins'
import { settings } from '@/config'

export default {
  name: 'Menu',
  components: {
    Hyperlink,
  },
  mixins: [theme],
  data() {
    return {
      panel: 0,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' },
      ],
    }
  },

  computed: {
    ...mapGetters(['user', 'getLastSavedPuzzles', 'getSolvedToday']),
    currentDate() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: settings.timeZone }
      const dailyPuzzleDate = new Date()
      return dailyPuzzleDate.toLocaleDateString(this.$i18n.locale, options)
    },
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    lastSavedPuzzles() {
      return this.getLastSavedPuzzles
    },
    navigation() {
      return this.$store.getters.navigation
    },
  },
  async mounted() {
    const com = new Com()
    if (this.isAuthenticated) {
      const res = await com.getLastSavedPuzzles()
      if (typeof res.data !== 'undefined') await this.$store.dispatch('setLastSavedPuzzles', res.data.puzzles)
    }
  },
  methods: {
    ...mapActions(['showNavigation', 'hideNavigation', 'showLoader', 'setPurpose', 'hideLoader']),
    formatNumber(number) {
      return new Intl.NumberFormat(this.$i18n.locale).format(number)
    },
    getHrefByLevel(level) {
      return this.$t(`hyperlinks.level_${level}.href`)
    },
    getHrefByPuzzleId(number) {
      return this.$t('hyperlinks.id.href', { number })
    },
    getPurposeByLevel(level) {
      return this.$t(`hyperlinks.level_${level}.purpose`)
    },
    getPurposeById(number) {
      return this.$t('hyperlinks.id.purpose', { number: this.formatNumber(number) })
    },
    gotoDailySudoku() {
      this.hideNavigation()
      this.setPurpose(this.$t('hyperlinks.daily_sudoku.purpose'))
      this.showLoader()
      setTimeout(() => {
        location.href = String(this.$t('hyperlinks.daily_sudoku.href'))
      }, 1)
    },
    gotoDailySudokuWinners() {
      this.hideNavigation()
      this.setPurpose(this.$t('hyperlinks.winners.purpose'))
      this.showLoader()
      setTimeout(() => {
        location.href = String(this.$t('hyperlinks.winners.href'))
      }, 1)
    },
    gotoSavedPuzzles() {
      this.hideNavigation()
      this.setPurpose(this.$t('hyperlinks.saved_puzzles.purpose'))
      this.showLoader()
      setTimeout(() => {
        location.href = String(this.$t('hyperlinks.saved_puzzles.href'))
      }, 1)
    },
    gotoSolvedPuzzles() {
      this.hideNavigation()
      this.setPurpose(this.$t('hyperlinks.solved_puzzles.purpose'))
      this.showLoader()
      setTimeout(() => {
        location.href = String(this.$t('hyperlinks.solved_puzzles.href'))
      }, 1)
    },
    print() {
      const self = this
      this.hideNavigation()
      this.setPurpose(this.$t('components.app.navigation.list_item_print_aria_label'))
      this.showLoader()
      setTimeout(() => {
        window.print()
        self.hideLoader({})
      }, 1)
    },
    toggleTheme() {
      const darkMode = !this.$vuetify.theme.dark
      this.$cookies.set('darkMode', darkMode, '1y')
      this.$vuetify.theme.dark = darkMode
      this.hideNavigation()
    },
  },
}
</script>

<style lang="scss">
.theme--dark.v-navigation-drawer {
  background-color: #1e1e1e !important;
}
.navigation {
  &__btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    left: -5px !important;
    top: 115px;
    width: 42px !important;
    height: 42px !important;
  }
  &__hyperlink a {
    text-decoration: none;
  }
  @media (min-width: 413px) {
    &__btn {
      width: 48px !important;
      height: 48px !important;
    }
  }
  @media (min-width: 600px) {
    &__btn {
      width: 52px !important;
      height: 52px !important;
    }
  }
}
</style>
