<template>
  <div class="d-inline">
    <v-btn :aria-label="ariaLabel" rounded text exact @click.prevent="go()">
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Btn',
  props: {
    href: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions(['showLoader', 'hideNavigation', 'setPurpose']),
    go() {
      this.hideNavigation()
      this.setPurpose(this.ariaLabel)
      this.showLoader()
      setTimeout(() => {
        location.href = this.href
      }, 1)
    },
  },
}
</script>

<style lang="scss"></style>
