import { DateTime } from 'luxon'

class DailySudoku {
  constructor() {}

  getDefaultCalendar() {
    const now = DateTime.local().startOf('day')
    const currentMonth = now.toFormat('L')
    const firstOfMonth = DateTime.local(now.year, now.month, 1)
    const lastOfMonth = firstOfMonth.plus({ month: 1 }).minus({ days: 1 })
    const firstCalendarWeekofMonth = parseInt(firstOfMonth.toFormat('W'))
    const firstOfMonthWeekday = firstOfMonth.toFormat('c') - 1
    const firstDayOfCalendar = firstOfMonth.minus({ days: firstOfMonthWeekday })
    const lastOfMonthWeekday = lastOfMonth.toFormat('c') - 1
    const lastDayOfCalendar = firstOfMonth.plus({ month: 1 }).plus({ days: 6 - lastOfMonthWeekday })
    const numberOfDays = lastDayOfCalendar.diff(firstDayOfCalendar, 'days').toObject().days

    let days = []
    let values = []
    let weeks = []

    for (let i = 1; i <= numberOfDays; i++) {
      let day = firstDayOfCalendar.plus({ days: i - 1 })
      days = [...days, day.toFormat('d')]
      let value = day.toFormat('L') === currentMonth && day < now ? 0 : null
      values = [...values, value]
      if (i % 7 === 0) {
        weeks = [
          ...weeks,
          {
            calendarWeek: `${firstCalendarWeekofMonth + i / 7 - 1}`,
            days,
            values,
          },
        ]
        days = []
        values = []
      }
    }

    return { weeks }
  }
}

export { DailySudoku }
