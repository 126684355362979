const version = {
  actual: '2024.3.15',
  splashCookie: '2021.2.0',
  path: '/2024-3',
  splashscreen: {
    displayUntil: '2021-04-02',
  },
}

export { version }
