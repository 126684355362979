const payment = {
  paypal: {
    clientId: process.env.VUE_APP_PAYPAL_CLIENTID,
    currency: 'EUR',
  },
  currency: 'EUR',
  tax: 19,
}

export { payment }
