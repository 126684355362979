import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

// Solid
import {
  faArrowRight as fasArrowRight,
  faBars as fasBars,
  faBell as fasBell,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faComment as fasComment,
  faCookieBite as fasCookieBite,
  faHeart as fasHeart,
  faMapMarker as fasMapMarker,
  faShield as fasShield,
  faSortUp as fasSortUp,
  faStar as fasStar,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
library.add(
  fasArrowRight,
  fasBars,
  fasBell,
  fasCheck,
  fasCheckCircle,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasCircle,
  fasComment,
  fasCookieBite,
  fasHeart,
  fasMapMarker,
  fasShield,
  fasSortUp,
  fasStar,
  fasTimes,
  fasTimesCircle
)

// Regular
import {
  faBars as farBars,
  faCheck as farCheck,
  faClock as farClock,
  faCloud as farCloud,
  faCopyright as farCopyright,
  faEllipsisH as farEllipsisH,
  faLongArrowUp as farLongArrowUp,
  faMapMarker as farMapMarker,
  faMapMarkerAlt as farMapMarkerAlt,
  faMicrochipAi as farMicrochipAi,
  faMinus as farMinus,
  faMinusCircle as farMinusCircle,
  faPlusCircle as farPlusCircle,
  faSquare as farSquare,
  faStar as farStar,
  faWandSparkles as farWandSparkles,
} from '@fortawesome/pro-regular-svg-icons'
library.add(
  farBars,
  farCheck,
  farClock,
  farCloud,
  farCopyright,
  farEllipsisH,
  farLongArrowUp,
  farMapMarker,
  farMapMarkerAlt,
  farMicrochipAi,
  farMinus,
  farMinusCircle,
  farPlusCircle,
  farSquare,
  farStar,
  farWandSparkles
)

// Light
import {
  faArrowAltToRight as falArrowAltToRight,
  faBars as falBars,
  faBrainCircuit as falBrainCircuit,
  faBug as falBug,
  faCheckSquare as falCheckSquare,
  faCircle as falCircle,
  faCircleInfo as falCircleInfo,
  faClipboardCheck as falClipboardCheck,
  faClock as falClock,
  faCookieBite as falCookieBite,
  faEnvelope as falEnvelope,
  faExchange as falExchange,
  faExclamation as falExclamation,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faExpand as falExpand,
  faGlobe as falGlobe,
  faGraduationCap as falGraduationCap,
  faHighlighter as falHighlighter,
  faInfo as falInfo,
  faLongArrowDown as falLongArrowDown,
  faNewspaper as falNewspaper,
  faPaperPlane as falPaperPlane,
  faPencil as falPencil,
  faPlusCircle as falPlusCircle,
  faPrint as falPrint,
  faRabbit as falRabbit,
  faRabbitFast as falRabbitFast,
  faRectangleAd as falRectangleAd,
  faRedo as falRedo,
  faSave as falSave,
  faSortDown as falSortDown,
  faSpinnerThird as falSpinnerThird,
  faStar as falStar,
  faTimes as falTimes,
  faUserCircle as falUserCircle,
  faVacuum as falVacuum,
  faWandSparkles as falWandSparkles,
} from '@fortawesome/pro-light-svg-icons'
library.add(
  falArrowAltToRight,
  falBars,
  falBrainCircuit,
  falBug,
  falCheckSquare,
  falCircle,
  falCircleInfo,
  falClipboardCheck,
  falClock,
  falCookieBite,
  falEnvelope,
  falExchange,
  falExclamation,
  falExclamationCircle,
  falExclamationTriangle,
  falExpand,
  falGlobe,
  falGraduationCap,
  falHighlighter,
  falInfo,
  falLongArrowDown,
  falNewspaper,
  falPaperPlane,
  falPencil,
  falPlusCircle,
  falPrint,
  falRabbit,
  falRabbitFast,
  falRectangleAd,
  falRedo,
  falSave,
  falSortDown,
  falSpinnerThird,
  falStar,
  falTimes,
  falUserCircle,
  falVacuum,
  falWandSparkles
)

// DuoTone
import {
  faAt as fadAt,
  faBars as fadBars,
  faCheck as fadCheck,
  faCheckSquare as fadCheckSquare,
  faCoffeeTogo as fadCoffeeTogo,
  faComments as fadComments,
  faCookieBite as fadCookieBite,
  faEngineWarning as fadEngineWarning,
  faEnvelope as fadEnvelope,
  faExternalLink as fadExternalLink,
  faEye as fadEye,
  faFileAlt as fadFileAlt,
  faGavel as fadGavel,
  faHighlighter as fadHighlighter,
  faHighlighterLine as fadHighlighterLine,
  faHomeHeart as fadHomeHeart,
  faInfoCircle as fadInfoCircle,
  faLightbulb as fadLightbulb,
  faLightbulbOn as fadLightbulbOn,
  faListUl as fadListUl,
  faMapMarkerCheck as fadMapMarkerCheck,
  faMedal as fadMedal,
  faPencil as fadPencil,
  faPlayCircle as fadPlayCircle,
  faPowerOff as fadPowerOff,
  faPrint as fadPrint,
  faQuestionCircle as fadQuestionCircle,
  faShippingFast as fadShippingFast,
  faSpinner as fadSpinner,
  faStopCircle as fadStopCircle,
  faTasks as fadTasks,
  faTimes as fadTimes,
  faTimesCircle as fadTimesCircle,
  faTrophyAlt as fadTrophyAlt,
  faUserGraduate as fadUserGraduate,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  fadAt,
  fadBars,
  fadCheck,
  fadCheckSquare,
  fadCoffeeTogo,
  fadComments,
  fadCookieBite,
  fadEngineWarning,
  fadEnvelope,
  fadExternalLink,
  fadEye,
  fadFileAlt,
  fadGavel,
  fadHighlighter,
  fadHighlighterLine,
  fadHomeHeart,
  fadInfoCircle,
  fadLightbulb,
  fadLightbulbOn,
  fadListUl,
  fadMapMarkerCheck,
  fadMedal,
  fadPencil,
  fadPlayCircle,
  fadPowerOff,
  fadPrint,
  fadQuestionCircle,
  fadShippingFast,
  fadSpinner,
  fadStopCircle,
  fadTasks,
  fadTimes,
  fadTimesCircle,
  fadTrophyAlt,
  fadUserGraduate
)

// Brands
import { faAmazon as fabAmazon, faJira as fabJira, faYoutube as fabYoutube } from '@fortawesome/free-brands-svg-icons'
library.add(fabAmazon, fabJira, fabYoutube)

config.autoAddCss = false

Vue.component('FaIcon', FontAwesomeIcon)
Vue.component('FaLayers', FontAwesomeLayers)

export default {
  components: {
    FontAwesomeIcon,
    FontAwesomeLayers,
  },
}
