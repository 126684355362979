<template>
  <div v-if="!isSupporter" class="text-center rounded-lg" :class="$theme.app.login.supporter.backgroundColor">
    <v-hover v-slot="{ hover }" close-delay="10000">
      <div v-if="!isSupporter" class="text-center rounded-lg" :class="$theme.app.login.supporter.backgroundColor">
        <div :class="[hover ? 'pt-5 pb-4' : 'py-2']">
          <fa-layers class="fa-4x">
            <fa-icon :icon="['fas', 'comment']" class="white--text" />
            <fa-icon :icon="['fas', 'heart']" class="red--text text--accent-3" transform="shrink-8" />
          </fa-layers>
        </div>
        <div v-show="hover">
          <p
            class="mb-1 px-3 font-weight-medium"
            :class="$theme.app.login.supporter.textColor"
            style="font-size: 21px; line-height: 1.5rem"
          >
            Unterstütze Sudoku Knacker und genieße dein Rätsel-Erlebnis<br /><strong>ohne Werbeanzeigen</strong>.
          </p>
          <v-btn href="/de/unterstuetzer_innen" rounded color="primary" class="mb-5 mt-4"> Mehr Informationen </v-btn>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { theme } from '@/mixins'
import { settings } from '@/config'

export default {
  name: 'Login',
  mixins: [theme],
  computed: {
    ...mapGetters(['profile', 'user', 'actualPeriod', 'isSupporter', 'counter']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
  },
  methods: {
    formatDate(val) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: settings.timeZone }
      return val.toLocaleDateString(this.$i18n.locale, options)
    },
  },
}
</script>

<style lang="scss"></style>
