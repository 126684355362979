<template>
  <div>
    <form novalidate @submit.prevent="submit()">
      <div id="login-processed">
        <p>{{ $t('components.login_processed.p_text_1') }}</p>
        <v-row class="ma-4 mt-0 text-center">
          <v-col v-for="i in [0, 1, 2]" :key="i" class="pa-0 pa-sm-1 mx-n1 mx-sm-0">
            <input
              :id="`login-processed-form-element-${i}`"
              v-model="password[i]"
              aria-label=""
              class="login-processed__input-field text-center"
              :disabled="disableFormElements"
              type="text"
              maxlength="1"
              @blur="updatePassword(i, $event)"
              @input="updatePassword(i, $event)"
              @paste="onPaste"
            />
          </v-col>
          <v-col class="pa-0 pa-sm-1 pt-3 pt-sm-5 mx-n1 mx-sm-0">
            <fa-icon :icon="['far', 'minus']" />
          </v-col>
          <v-col v-for="i in [3, 4, 5]" :key="i" class="pa-0 pa-sm-1 mx-n1 mx-sm-0">
            <input
              :id="`login-processed-form-element-${i}`"
              v-model="password[i]"
              aria-label=""
              class="login-processed__input-field text-center"
              :disabled="disableFormElements"
              type="text"
              maxlength="1"
              @blur="updatePassword(i, $event)"
              @input="updatePassword(i, $event)"
              @paste="onPaste"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="showAuthenticateButton" class="pb-3 text-center">
        <v-btn
          id="login-processed-form-element-6"
          :disabled="disableFormElements"
          class=""
          color="$theme.btn.color"
          outlined
          rounded
          type="submit"
        >
          <fa-icon class="mr-2" size="lg" :icon="['fal', 'clipboard-check']" />{{ $t('components.login_processed.btn_submit') }}
        </v-btn>
        <v-alert v-if="passwordError" outlined type="error" border="left" class="text-left mb-0 mt-4">{{
          $t('components.login_processed.alert_otp_validation')
        }}</v-alert>
      </div>
      <div v-else class="text-center">
        <div>
          <fa-icon id="envelope-icon" class="login-processed__envelope-icon ml-2" :icon="['fad', 'envelope']" />
          <fa-icon id="shipping-icon" class="login-processed__shipping-icon" size="2x" :icon="['fad', 'shipping-fast']" />
        </div>

        <p class="text-center mb-3">{{ $t('components.login_processed.p_on_its_way') }}</p>
        <p class="login__caption text-caption">
          {{ $t('components.login_processed.p_caption_1') }}
        </p>
      </div>
      <p class="text-caption text-center">
        <i18n path="components.login_processed.p_caption_2">
          <template #otherEmail>
            <a href="#" @click.prevent="reset()">{{ $t('components.login_processed.other_email') }}</a>
          </template>
        </i18n>
      </p>
    </form>
  </div>
</template>

<script>
import gsap from 'gsap'
import { mapActions } from 'vuex'
import { Com } from '@/util'
import jwt_decode from 'jwt-decode'

export default {
  name: 'LoginProcessed',
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      disableFormElements: false,
      password: ['', '', '', '', '', ''],
      passwordError: false,
      showAuthenticateButton: false,
    }
  },
  computed: {
    otp() {
      return this.password.join('')
    },
  },
  mounted() {
    const tl = gsap.globalTimeline
    const timeline = gsap.timeline()

    tl.add(timeline)
    timeline.set('#envelope-icon', {
      x: 16,
      width: '0',
      height: '0',
    })
    timeline.set('#shipping-icon', { x: -133 })
    timeline.fromTo('#login-processed', 0.5, { y: -50 }, { y: 0, opacity: 1 })
    timeline.to('#shipping-icon', 2, { x: -11, opacity: 1 })
    timeline.to('#shipping-icon', 2, { x: 111, opacity: 0 })
    timeline.to('#envelope-icon', 0.5, { rotation: 360, opacity: 1, width: '30px', height: '30px' }, '<')
    document.getElementById('login-processed-form-element-0').focus()
  },
  methods: {
    ...mapActions(['hideLoader', 'showLoader', 'setPurpose', 'setAuthenticationExpires', 'setProfile']),
    updatePassword(key) {
      this.passwordError = false
      this.showAuthenticateButton = this.otp.length
      const val = String(this.password[key])
      const upperCase = val.toUpperCase()
      this.password[key] = upperCase.match(/^[ABCDEFGHJKLMNPRSTUVWXYZ]/) ?? ''
      if (this.password[key] !== '') document.getElementById(`login-processed-form-element-${key + 1}`).focus()
    },
    onPaste(event) {
      const clipboard = event.clipboardData.getData('text')
      const uppercase = clipboard.toUpperCase()
      const val = uppercase.replace(/[^ABCDEFGHJKLMNPRSTUVWXYZ]/g, '')
      if (val.length === 6) {
        document.getElementById('login-processed-form-element-4').focus()
        this.password = val.split('')
      }
    },
    async submit() {
      if (this.$cookies.isKey('loginToken')) {
        this.disableFormElements = true
        this.setPurpose(this.$t('components.login_processed.purpose'))
        this.showLoader()
        const loginToken = this.$cookies.get('loginToken')
        await this.authenticate(loginToken)
      }
    },
    async authenticate(loginToken) {
      const com = new Com()
      const response = await com.authenticate({ loginToken, password: String(this.otp).toUpperCase() })
      if (typeof response === 'object') {
        if (response.data.success === true) {
          setTimeout(async () => {
            const authenticationToken = response.data.authenticationToken
            const authenticationTokenDecoded = jwt_decode(authenticationToken)
            this.setAuthenticationExpires(authenticationTokenDecoded.exp)
            this.reset()
            this.$cookies.set('authenticationToken', authenticationToken, '365d')
            location.reload()
          }, 1)
        } else {
          setTimeout(async () => {
            this.password = ['', '', '', '', '', '']
            this.passwordError = true
            this.disableFormElements = false
            this.hideLoader()
          }, 1)
        }
      }
    },
    reset() {
      this.$cookies.remove('emailSent')
      this.$cookies.remove('loginToken')
      this.$store.dispatch('setEmailSentExpires', 0)
      this.$emit('reset')
    },
  },
}
</script>

<style lang="scss">
.login-processed {
  &__envelope-icon,
  &__shipping-icon {
    opacity: 0;
  }
  &__input-field {
    font-size: 28px;
    height: 48px;
    width: 21px;
    border-radius: 4px;
  }
  @media (min-width: 413px) {
    &__input-field {
      font-size: 36px;
      height: 52px;
      width: 36px;
    }
  }
}
#app.theme--light {
  .login-processed {
    &__input-field {
      border: 1px solid rgba(0, 0, 0, 0.38);
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

#app.theme--dark {
  .login-processed {
    &__input-field {
      border: 1px solid rgba(255, 255, 255, 0.38);
      color: rgba(255, 255, 255, 0.9);
    }
  }
}
</style>
