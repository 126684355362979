export default {
  state: {
    functionality: false,
    performance: false,
    targeting: true,
    unclassified: false,
  },
  mutations: {
    SET_COOKIE_CONSENT_FUNCTIONALITY(state, value) {
      state.functionality = value
    },
    SET_COOKIE_CONSENT_PERFORMANCE(state, value) {
      state.performance = value
    },
    SET_COOKIE_CONSENT_TARGETING(state, value) {
      state.targeting = value
    },
    SET_COOKIE_CONSENT_UNCLASSIFIED(state, value) {
      state.unclassified = value
    },
  },
  actions: {
    updateCookieConsentFunctionality({ commit }, value) {
      commit('SET_COOKIE_CONSENT_FUNCTIONALITY', value)
    },
    updateCookieConsentPerformance({ commit }, value) {
      commit('SET_COOKIE_CONSENT_PERFORMANCE', value)
    },
    updateCookieConsentTargeting({ commit }, value) {
      commit('SET_COOKIE_CONSENT_TARGETING', value)
    },
    updateCookieConsentUnclassified({ commit }, value) {
      commit('SET_COOKIE_CONSENT_UNCLASSIFIED', value)
    },
  },
  getters: {
    cookieConsentFunctionality: (state) => state.functionality,
    cookieConsentPerformance: (state) => state.performance,
    cookieConsentTargeting: (state) => state.targeting,
    cookieConsentUnclassified: (state) => state.unclassified,
  },
  modules: {},
}
