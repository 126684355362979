<template>
  <div>
    <div class="cursor-pointer" @click="goToDailySudoku()">
      <fa-icon size="xs" :icon="['fas', 'chevron-right']" class="float-right ma-2" transform="down-4" />
      <h2 class="daily-sudoku__title">
        {{ $t('components.app.daily_sudoku.h2_title') }}
      </h2>
    </div>
    <h3>{{ getSubtitle() }}</h3>
    <v-simple-table class="mb-3" :class="$theme.app.dailySudoku.table.backgroundColor" dense>
      <template #default>
        <thead>
          <tr>
            <th class="text-center pa-0">{{ $t('components.app.daily_sudoku.calendar_week') }}</th>
            <th v-for="index in 7" :key="index" class="text-center pa-0">
              {{ $t(`globals.weekdays_abbreviation.day_${index}`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="week in weeks" :key="week.calendarWeek">
            <td>{{ week.calendarWeek }}</td>
            <td v-for="(day, index) in week.days" :key="day" style="vertical-align: top">
              <div
                aria-disabled="true"
                class="daily-sudoku__day mr-n3 text-right"
                :class="week.values[index] === null ? $theme.app.dailySudoku.table.daysInactive.textColor : ''"
              >
                {{ day }}
              </div>
              <div v-if="week.values[index] !== null" class="mx-n1 mt-n1 mb-1">
                <fa-layers class="pa-0">
                  <fa-icon v-if="week.values[index] > -1" class="white--text" :icon="['fas', 'circle']" transform="grow-4 up-4" />
                  <fa-icon v-if="week.values[index] === 1" class="green--text" :icon="['fas', 'check-circle']" transform="grow-4 up-4" />
                  <fa-icon
                    v-if="week.values[index] === 0"
                    class="grey--text text--lighten-1"
                    :icon="['fas', 'times-circle']"
                    transform="grow-4 up-4"
                  />
                </fa-layers>
              </div>
              <div v-else class="mx-n1 mt-n1 mb-1">
                <fa-layers class="pa-0">
                  <fa-icon class="transparent--text" :icon="['fas', 'circle']" transform="grow-4 up-4" />
                </fa-layers>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p class="daily-sudoku__caption text-caption">{{ $t('components.app.daily_sudoku.p_caption') }}</p>
    <div class="text-center">
      <v-btn rounded outlined color="$theme.btn.color" :href="$t('hyperlinks.winners.href')" :aria-label="$t('hyperlinks.winners.purpose')">
        <fa-icon class="yellow--text text--darken-2 mr-2" :icon="['fad', 'medal']" size="lg" />{{
          $t('components.daily_sudoku.btn_winners')
        }}
      </v-btn>
    </div>
    <v-divider class="my-3" />
  </div>
</template>

<script>
import { Com } from '@/util'
import { DailySudoku } from '@/util'
import { mapGetters, mapActions, mapState } from 'vuex'
import { theme } from '@/mixins'

export default {
  name: 'DailySudoku',
  mixins: [theme],
  computed: {
    ...mapGetters(['user']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    ...mapState(['dailySudoku']),
    weeks() {
      return typeof this.dailySudoku.statistics !== 'undefined' ? this.dailySudoku.statistics.weeks : []
    },
  },
  beforeMount() {
    const dailySudoku = new DailySudoku()
    this.setStatistics({ statistics: dailySudoku.getDefaultCalendar() })
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    ...mapActions(['setStatistics', 'setPurpose', 'showLoader']),
    goToDailySudoku() {
      this.setPurpose(this.$t('hyperlinks.daily_sudoku.purpose'))
      this.showLoader()
      setTimeout(() => {
        location.href = String(this.$t('hyperlinks.daily_sudoku.href'))
      }, 1)
    },
    getSubtitle() {
      const date = new Date()
      const currentYear = date.getFullYear()
      const currentMonth = this.$t(`globals.months.month_${date.getMonth() + 1}`)
      return `${currentMonth} ${currentYear}`
    },
    async getStatistics() {
      if (this.isAuthenticated) {
        const com = new Com()
        const res = await com.getDailySudokuStatistics()
        if (typeof res.data !== 'undefined') await this.setStatistics(res.data)
      }
    },
  },
}
</script>

<style lang="scss">
.daily-sudoku {
  &__day {
    font-size: 14px;
  }
  &__caption {
    line-height: 14px !important;
  }
}
</style>
