const sudoku = {
  data() {
    return {}
  },
  computed: {
    sudoku() {
      return this.$store.getters.sudoku
    },
    speedMode() {
      return this.$store.getters.getSpeedMode
    },
    puzzle() {
      return this.sudoku.puzzle.split('')
    },
    highlightedFields() {
      if (this.sudoku.activeCellId == null) return []
      const fieldNum = this.sudoku.activeCellId
      const size = 9 // Größe des Sudokufeldes
      const subgridSize = 3 // Größe der Unterquadrate
      let row = Math.floor(fieldNum / size)
      let col = fieldNum % size
      let highlights = new Set()

      // Hinzufügen aller Felder in der gleichen Zeile, außer dem aktiven Feld
      for (let i = 0; i < size; i++) {
        if (i !== col) {
          // Prüfen, ob es nicht das aktive Feld ist
          highlights.add(row * size + i)
        }
      }

      // Hinzufügen aller Felder in der gleichen Spalte, außer dem aktiven Feld
      for (let i = 0; i < size; i++) {
        if (i !== row) {
          // Prüfen, ob es nicht das aktive Feld ist
          highlights.add(i * size + col)
        }
      }

      // Ermittlung des Startpunktes des 3x3-Quadrats
      let startRow = row - (row % subgridSize)
      let startCol = col - (col % subgridSize)

      // Hinzufügen aller Felder im 3x3-Unterquadrat, außer dem aktiven Feld
      for (let i = 0; i < subgridSize; i++) {
        for (let j = 0; j < subgridSize; j++) {
          let currentRow = startRow + i
          let currentCol = startCol + j
          if (!(currentRow === row && currentCol === col)) {
            // Prüfen, ob es nicht das aktive Feld ist
            highlights.add(currentRow * size + currentCol)
          }
        }
      }

      // Konvertieren des Sets in ein Array und Rückgabe
      return Array.from(highlights)
    },
  },
  methods: {
    getEntryOfCell() {
      if (typeof this.sudoku.entries[this.sudoku.activeCellId] !== 'undefined' && this.sudoku.entries[this.sudoku.activeCellId] !== null) {
        return this.sudoku.entries[this.sudoku.activeCellId]
      }
      return false
    },
    btnHasCheckInFlag(digit) {
      return this.sudoku.checkInDigit[this.sudoku.activeCellId] === digit || this.getEntryOfCell() === digit
    },
    getButtonId(digit) {
      if (this.btnHasCheckInFlag(digit)) {
        return `sudoku-buttons-btn-check-in-${digit}`
      }
      return `sudoku-buttons-btn-note-${digit}`
    },
    noteIsActive(digit) {
      const bin = Math.pow(2, digit - 1)
      const note = this.sudoku.notes[this.sudoku.activeCellId]
      return (bin & note) === bin
    },
    toggleNoteByDigit(cellId, digit) {
      if (digit === this.getEntryOfCell()) {
        this.$store.dispatch('setEntry', {
          elementId: cellId,
          val: undefined,
        })
        return true
      }
      if (cellId !== this.sudoku.activeCellId) return true
      if (this.sudoku.checkInDigit[cellId] === digit) {
        this.$store.dispatch('setCheckInDigit', { cellId, digit: null })
        this.removeNoteIconFromButton(cellId, digit)
        this.$store.dispatch('setEntry', {
          elementId: cellId,
          val: digit,
        })
        return true
      }
      this.$store.dispatch('setCheckInDigit', { cellId: cellId, digit: digit })
      if (this.noteIsActive(digit)) {
        this.addNoteIconToButton(cellId, digit)
      } else {
        this.removeNoteIconFromButton(cellId, digit)
      }
      setTimeout(
        () => {
          const activeCellId = cellId
          if (this.sudoku.checkInDigit[activeCellId] === digit)
            this.$store.dispatch('setCheckInDigit', { cellId: activeCellId, digit: null })
        },
        this.speedMode ? 500 : 2500,
        digit
      )
    },
    addNoteIconToButton(cellId, digit) {
      const bin = Math.pow(2, digit - 1)
      const note = this.sudoku.notes[cellId]
      if ((bin & note) === bin) {
        this.$store.dispatch('setNote', {
          elementId: cellId,
          val: note - bin,
        })
      }
    },
    removeNoteIconFromButton(cellId, digit) {
      const bin = Math.pow(2, digit - 1)
      const note = this.sudoku.notes[cellId]
      if (typeof note !== 'undefined' && note !== null && (bin & note) !== bin) {
        this.$store.dispatch('setNote', {
          elementId: cellId,
          val: note + bin,
        })
      }
    },
  },
}

export { sudoku }
