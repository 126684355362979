export default {
  state: {
    adSlots: [],
  },
  getters: {
    getAdSlots(state) {
      return state.adSlots
    },
  },
  mutations: {
    SET_AD_SLOTS(state, val) {
      state.adSlots = val
    },
    ADD_TO_AD_SLOTS(state, adSlotId) {
      if (!state.adSlots.includes(adSlotId)) {
        state.adSlots.push(adSlotId)
      }
    },
    REMOVE_AD_SLOT(state, adSlotId) {
      state.adSlots = state.adSlots.filter((adSlot) => adSlot.id !== adSlotId)
    },
  },
  actions: {
    setAdSlots({ commit }, val) {
      commit('SET_AD_SLOTS', val)
    },
    addAdSlot({ commit }, adSlotId) {
      commit('ADD_TO_AD_SLOTS', adSlotId)
    },
    removeAdSlot({ commit }, adSlotId) {
      commit('REMOVE_AD_SLOT', adSlotId)
    },
  },
  modules: {},
}
