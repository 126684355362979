var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pr-3 pr-md-6",attrs:{"cols":"0","md":"5","lg":"4"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"blue-grey"}}),_vm._l((_vm.items),function(item,index){return _c('v-tab',{key:("tab-" + index),class:[_vm.$theme.app.tabs.color]},[_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":['fal', item.icon],"size":"lg"}}),_vm._v(_vm._s(item.title)+" ")],1)})],2),_c('v-tabs-items',{staticClass:"mt-4",class:[_vm.$theme.app.tabs.color],model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',{staticClass:"mb-4"},[_c('h2',[_vm._v("Sudoku Knacker informiert")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":_vm.$t('hyperlinks.synapses.20240721.href'),"text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 21. Juli 2024")],1),_c('div',[_vm._v("Neuer Artikel „"+_vm._s(_vm.$t('synapses.20240721.h1_title'))+"“")])])])]}}],null,false,4239610123)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":_vm.$t('hyperlinks.synapses.20240623.href'),"text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 23. Juni 2024")],1),_c('div',[_vm._v("Neuer Artikel „"+_vm._s(_vm.$t('synapses.20240623.h1_title'))+"“")])])])]}}],null,false,382290219)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":_vm.$t('hyperlinks.synapses.20240608.href'),"text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 8. Juni 2024")],1),_c('div',[_vm._v("Neuer Artikel „"+_vm._s(_vm.$t('synapses.20240608.h1_title'))+"“")])])])]}}],null,false,4107286898)}),(false)?[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":_vm.$t('hyperlinks.synapses.20240526.href'),"text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 26. Mai 2024")],1),_c('div',[_vm._v("Neuer Artikel „"+_vm._s(_vm.$t('synapses.20240526.h1_title'))+"“")])])])]}}],null,false,704323859)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":_vm.$t('hyperlinks.synapses.20240516.href'),"text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 16. Mai 2024")],1),_c('div',[_vm._v("Neuer Artikel „"+_vm._s(_vm.$t('synapses.20240516.h1_title'))+"“")])])])]}}],null,false,3196355536)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":_vm.$t('hyperlinks.synapses.20240505.href'),"text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 5. Mai 2024")],1),_c('div',[_vm._v("Neuer Artikel „"+_vm._s(_vm.$t('synapses.20240505.h1_title'))+"“")])])])]}}],null,false,1130681698)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":"/de/akademie/lektionen/methoden/der-versteckte-einer","text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? 'blue-grey lighten-5' : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'graduation-cap'],"size":"xs"}}),_vm._v("Akademie - 1. Mai 2024")],1),_c('div',[_vm._v("Neue Lektion „Der Versteckte Einer“")])])])]}}],null,false,1697991422)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":"/de/synapsen/2024/04/21/die-faszinierende-geschichte-des-sudoku-ursprung-und-globale-verbreitung","text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 21. April 2024")],1),_c('div',[_vm._v("Neuer Artikel „"+_vm._s(_vm.$t('synapses.20240421.h1_title'))+"“")])])])]}}],null,false,3821324539)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":"/de/synapsen/2024/04/11/ein-offenes-wort-warum-werbung-auf-sudoku-knacker-unverzichtbar-ist","text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 11. April 2024")],1),_c('div',[_vm._v("Neuer Artikel „Ein offenes Wort: Warum Werbung auf Sudoku Knacker unverzichtbar ist”")])])])]}}],null,false,875460631)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":"/de/akademie/lektionen/methoden/das-full-house","text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'graduation-cap'],"size":"xs"}}),_vm._v("Akademie - 10. April 2024")],1),_c('div',[_vm._v("Neue Lektion „Das Full House“")])])])]}}],null,false,3555863681)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":"/de/synapsen/2024/04/02/willkommen-in-der-sudoku-knacker-akademie","text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 2. April 2024")],1),_c('div',[_vm._v("Neuer Artikel „Deine Eintrittskarte in die Welt der Sudoku-Meister“")])])])]}}],null,false,2261240855)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":"/de/akademie/lektionen/methoden/der-nackte-einer","text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'graduation-cap'],"size":"xs"}}),_vm._v("Akademie - 2. April 2024")],1),_c('div',[_vm._v("Neue Lektion „Der Nackte Einer“")])])])]}}],null,false,3406987474)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('Hyperlink',{attrs:{"href":"/de/synapsen/2024/03/26/entdecke-wie-sudoku-dich-geistig-fit-haelt","text-decoration":false}},[_c('div',{staticClass:"pa-2",class:[hover ? _vm.$theme.app.marginalContent.hover.backgroundColor : '', _vm.$theme.app.tabs.color]},[_c('h3',[_c('fa-icon',{staticClass:"mr-1",attrs:{"icon":['fal', 'brain-circuit'],"size":"xs"}}),_vm._v("Synapsen - 26. März 2024")],1),_c('div',[_vm._v("Neuer Artikel „Willkommen bei Synapsen: Dein regelmäßiges Sudoku-Brain-Gym!“")])])])]}}],null,false,2844617503)})]:_vm._e()],2)]),_c('v-tab-item',[_c('login',{staticClass:"mb-4"})],1)],1),_c('supporter'),_c('black-friday-ad'),_c('counter'),(!_vm.isSupporter)?[_c('Adslot',{staticClass:"mb-4",attrs:{"ad-unit":"rectangle","ad-class":"adslot__sidebar"}}),_c('v-divider',{staticClass:"my-3"})]:_vm._e(),_c('appInfo'),_c('daily-sudoku',{staticClass:"mb-6"})],2):_c('div',[_c('v-dialog',{attrs:{"scrollable":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"marginal-content__btn",attrs:{"color":_vm.$theme.app.navigation.buttons.open.color,"fab":"","fixed":"","title":_vm.$t('components.buttons.btn_menu'),"elevation":"4"}},'v-btn',attrs,false),on),[_c('fa-icon',{class:_vm.$theme.app.navigation.buttons.open.text,attrs:{"size":"2x","icon":['fal', 'user-circle']}})],1)]}}]),model:{value:(_vm.showMarginalContentDialog),callback:function ($$v) {_vm.showMarginalContentDialog=$$v},expression:"showMarginalContentDialog"}},[_c('v-card',{staticStyle:{"height":"100vh"}},[_c('v-card-title',{staticClass:"pa-2 pb-0"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"fab":"","text":""},on:{"click":function($event){_vm.showMarginalContentDialog = false}}},[_c('fa-icon',{class:_vm.$theme.app.navigation.buttons.open.text,attrs:{"size":"lg","icon":['fal', 'times']}})],1)],1),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('login',{staticClass:"mb-6"}),_c('supporter'),_c('black-friday-ad'),_c('counter'),_c('appInfo'),_c('daily-sudoku',{staticClass:"mb-6"})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }