<template>
  <div>
    <v-divider class="my-3" />
    <div class="mt-4 text-center">
      {{ $t('components.app.info.online') }} {{ online }} &middot; {{ $t('components.app.info.today') }} {{ today }} &middot;
      {{ $t('components.app.info.yesterday') }} {{ yesterday }}
    </div>
    <v-divider class="my-3" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { theme } from '@/mixins'
import { settings } from '@/config'
import { Com } from '@/util'

export default {
  name: 'Login',
  components: {},
  mixins: [theme],
  computed: {
    ...mapGetters(['profile', 'user', 'actualPeriod', 'isSupporter', 'counter']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    online() {
      return this.formatCounter(this.counter.online)
    },
    today() {
      return this.formatCounter(this.counter.today)
    },
    yesterday() {
      return this.formatCounter(this.counter.yesterday)
    },
  },
  async beforeMount() {
    const com = new Com()
    await com.updateCounter()
  },
  mounted() {
    setInterval(() => {
      const com = new Com()
      com.updateCounter()
    }, 300000)
  },
  methods: {
    formatDate(val) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: settings.timeZone }
      return val.toLocaleDateString(this.$i18n.locale, options)
    },
    formatCounter(number) {
      return new Intl.NumberFormat(this.$i18n.locale).format(number)
    },
  },
}
</script>

<style lang="scss"></style>
