export default {
  state: {
    loader: {
      show: true,
      purpose: '',
    },
  },
  mutations: {
    SET_LOADER(state, val) {
      state.loader.show = val
    },
    SET_PURPOSE(state, val) {
      state.loader.purpose = val
    },
  },
  actions: {
    showLoader({ commit }) {
      commit('SET_LOADER', true)
    },
    hideLoader({ commit }) {
      commit('SET_LOADER', false)
    },
    setPurpose({ commit }, purpose) {
      commit('SET_PURPOSE', purpose)
    },
  },
  modules: {},
}
