<template>
  <div class="consent-mode d-print-none">
    <v-btn
      v-show="showButton"
      class="consent-mode__btn"
      :color="$theme.app.navigation.buttons.open.color"
      fab
      fixed
      :title="$t('components.buttons.btn_cookie_script')"
      elevation="4"
      @click="showCookieConsent"
    >
      <div ref="targetContainer">
        <fa-icon size="2xl" :icon="['fad', 'cookie-bite']"></fa-icon>
      </div>
      <fa-icon
        v-if="!isSupporter && !cookieConsentTargeting"
        style="position: absolute; right: -3px; top: -6px; --fa-animation-duration: 2s; --fa-beat-scale: 0.85"
        size="lg"
        :icon="['fas', 'circle']"
        class="red--text text--darken-4 fa-beat"
      />
      <template v-else-if="!isSupporter">
        <fa-icon style="position: absolute; right: -3px; top: -6px" size="lg" :icon="['fas', 'circle']" class="white--text" />
        <fa-icon style="position: absolute; right: -3px; top: -6px" size="lg" :icon="['fas', 'circle-check']" class="green--text" />
      </template>
    </v-btn>
  </div>
</template>

<script>
import { theme } from '@/mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CookieScript',
  mixins: [theme],
  data() {
    return {
      showButton: false,
    }
  },
  computed: {
    ...mapGetters(['cookieConsentTargeting', 'isSupporter']),
  },
  mounted() {
    this.checkEZCMPAvailability()
    window.EzConsentCallback = (consent) => {
      this.updateCookieConsentTargeting(consent.marketing)
    }
  },
  methods: {
    ...mapActions(['updateCookieConsentTargeting']),
    checkEZCMPAvailability() {
      let elapsed = 0
      const checkInterval = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (typeof ezCMP !== 'undefined' && ezCMP.generateCMPFromPrivacyCenter) {
          this.showButton = true
          clearInterval(checkInterval)
        } else if (elapsed >= 5000) {
          clearInterval(checkInterval)
        }
        elapsed += 100
      }, 100)
    },
    showCookieConsent() {
      if (this.showButton) {
        // eslint-disable-next-line no-undef
        ezCMP.generateCMPFromPrivacyCenter()
      }
    },
  },
}
</script>

<style lang="scss">
#ezPrivacyCenter {
  display: none !important;
}
.consent-mode {
  &__btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    left: -5px !important;
    top: 165px;
    width: 42px !important;
    height: 42px !important;
  }

  #cookiescript_badge {
    all: initial !important;
  }

  #cookiescript_badgeimage,
  #cookiescript_badgesvg {
    width: 26px;
    height: 26px;

    & > img {
      max-width: 26px;
      max-height: 26px;
    }

    @media (min-width: 413px) {
      width: 30px;
      height: 30px;

      & > img {
        max-width: 30px;
        max-height: 30px;
      }
    }

    @media (min-width: 600px) {
      width: 34px;
      height: 34px;

      & > img {
        max-width: 34px;
        max-height: 34px;
      }
    }
  }

  #cookiescript_badgeimage {
    cursor: pointer;
  }

  #cookiescript_badgetext {
    display: none;
  }

  @media (min-width: 413px) {
    &__btn {
      width: 48px !important;
      height: 48px !important;
      top: 171px;
    }
  }

  @media (min-width: 600px) {
    &__btn {
      width: 52px !important;
      height: 52px !important;
      top: 175px;
    }
  }
}
</style>
