const themes = {
  default: {
    light: {
      app: {
        bar: {
          color: 'white',
        },
        body: {
          color: 'white',
          background: {
            imageId: '44656862',
          },
        },
        dailySudoku: {
          table: {
            backgroundColor: 'white',
            daysInactive: {
              textColor: 'grey--text text--lighten-1',
            },
          },
        },
        footer: {
          backgroundColor: 'white',
        },
        header: {
          imageId: '44656862',
        },
        marginalContent: {
          hover: {
            backgroundColor: 'blue-grey lighten-5',
          },
        },
        info: {
          backgroundColor: 'blue-grey lighten-4',
          statistics: {
            backgroundColor: 'white',
            solvedPuzzlesTotal: 'blue-grey lighten-4',
          },
        },
        tabs: {
          color: 'white black--text',
        },
        login: {
          avatar: {
            backgroundColor: 'grey--text text--lighten-2',
            foregroundColor: 'blue--text text--darken-3',
          },
          verificationCode: {
            backgroundColor: 'grey lighten-2',
          },
          supporter: {
            backgroundColor: 'blue lighten-3',
            textColor: 'white--text',
            buttonColor: 'black--text white',
          },
        },
        menu: {
          backgroundColor: 'blue-grey lighten-4',
          buttonColor: 'white',
        },
        themes: {
          ambience: {
            dawn: '291288415',
            sunrise: '50961283',
            solarNoon: '84628947',
            sunset: '97752724',
            dusk: '99129958',
            night: '94105540',
          },
        },
        navigation: {
          buttons: {
            open: {
              color: 'white',
              text: 'grey--text text--darken-3',
            },
            close: {
              color: 'transparent',
              text: 'grey--text text--darken-3',
            },
          },
        },
      },
      btn: {
        color: 'grey darken-4',
      },
      sudoku: {
        stars: {
          color1: {
            active: 'yellow--text text--accent-1',
            inactive: 'blue--text text--lighten-5',
          },
          color2: {
            active: 'grey--text text--lighten-1',
            inactive: 'grey--text text--lighten-1',
          },
        },
        buttons: {
          color: 'white',
        },
        elements: {
          active: {
            backgroundColor: 'blue lighten-4',
            backgroundWarningColor: 'red lighten-4',
            fontColor: 'blue--text text--darken-3',
            fontWarningColor: 'red--text text--darken-2',
          },
          highlight: {
            backgroundColor: 'yellow lighten-4',
          },
        },
        notes: {
          color: {
            active: 'grey--text text--lighten-2',
            inactive: 'black--text',
          },
        },
      },
      views: {
        dailySudoku: {
          vouchers: [
            {
              backgroundColor: 'amber accent-4',
              textColor: 'white--text',
            },
            {
              backgroundColor: 'blue-grey lighten-2',
              textColor: 'white--text',
            },
          ],
        },
        faq: {
          questions: {
            backgroundColor: 'grey lighten-4',
          },
        },
      },
    },
    dark: {
      app: {
        bar: {
          color: 'black',
        },
        body: {
          color: 'black',
          background: {
            imageId: '94105540',
          },
        },
        dailySudoku: {
          table: {
            backgroundColor: 'black',
            daysInactive: {
              textColor: 'grey--text text--darken-3',
            },
          },
        },
        footer: {
          backgroundColor: 'black',
        },
        header: {
          imageId: '94105540',
        },
        marginalContent: {
          hover: {
            backgroundColor: 'blue-grey darken-3',
          },
        },
        info: {
          backgroundColor: 'blue-grey darken-4',
          statistics: {
            backgroundColor: 'blue-grey darken-3',
            solvedPuzzlesTotal: 'blue-grey darken-4',
          },
        },
        tabs: {
          color: 'black white--text',
        },
        login: {
          avatar: {
            backgroundColor: 'grey--text text--darken-1',
            foregroundColor: 'grey--text text--lighten-3',
          },
          verificationCode: {
            backgroundColor: 'grey darken-1',
          },
          supporter: {
            backgroundColor: 'purple darken-3',
          },
        },
        menu: {
          backgroundColor: 'blue-grey darken-4',
          buttonColor: 'blue-grey darken-2',
        },
        themes: {
          ambience: {
            dawn: '291288415',
            sunrise: '50961283',
            solarNoon: '84628947',
            sunset: '97752724',
            dusk: '99129958',
            night: '94105540',
          },
        },
        navigation: {
          buttons: {
            open: {
              color: 'grey darken-4',
              text: 'grey--text text--darken-1',
            },
            close: {
              color: 'transparent',
              text: 'grey--text text--darken-1',
            },
          },
        },
      },
      btn: {
        color: 'grey lighten-3',
      },
      sudoku: {
        stars: {
          color1: {
            active: 'yellow--text text--accent-4',
            inactive: 'grey--text text--darken-3',
          },
          color2: {
            active: 'grey--text text--darken-4',
            inactive: 'grey--text text--darken-4',
          },
        },
        buttons: {
          color: 'grey darken-4',
        },
        elements: {
          active: {
            backgroundColor: 'blue darken-4',
            backgroundWarningColor: 'red darken-4',
            fontColor: 'blue--text text--lighten-4',
            fontWarningColor: 'red--text text--lighten-3',
          },
          highlight: {
            backgroundColor: 'brown darken-1',
          },
        },
        notes: {
          color: {
            active: 'grey--text text--darken-3',
            inactive: 'white--text',
          },
        },
      },
      views: {
        dailySudoku: {
          vouchers: [
            {
              backgroundColor: 'amber accent-4',
              textColor: 'white--text',
            },
            {
              backgroundColor: 'blue-grey darken-1',
              textColor: 'white--text',
            },
          ],
        },
        faq: {
          questions: {
            backgroundColor: 'grey darken-4',
          },
        },
      },
    },
  },
}

export { themes }
