import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const lastmod = new Date().toISOString()
const changefreq = 'weekly'
const priority = 0.8
const sitemap = { changefreq, priority, lastmod }
const meta = { sitemap }

export const routes = [
  {
    path: '/',
    alias: ['/de', '/de/sehr-einfach', '/de/einfach', '/de/mittel', '/de/schwierig', '/de/sehr-schwierig'],
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta,
    children: [
      {
        path: '',
      },
      {
        path: 'de',
      },
      {
        path: 'de/sehr-einfach',
      },
      {
        path: 'de/einfach',
      },
      {
        path: 'de/mittel',
      },
      {
        path: 'de/schwierig',
      },
      {
        path: 'de/sehr-schwierig',
      },
    ],
  },
  {
    path: '/de/akademie',
    component: () => import(/* webpackChunkName: "academy" */ '@/views/Academy/Academy.vue'),
    meta,
  },
  {
    path: '/de/akademie/lektionen/methoden/der-versteckte-einer',
    component: () => import(/* webpackChunkName: "academy" */ '@/views/Academy/lessons/methods/hidden-single/HiddenSingle.vue'),
    meta,
  },
  {
    path: '/de/akademie/lektionen/methoden/der-nackte-einer',
    component: () => import(/* webpackChunkName: "academy" */ '@/views/Academy/lessons/methods/naked-single/NakedSingle.vue'),
    meta,
  },
  {
    path: '/de/akademie/lektionen/methoden/das-full-house',
    component: () => import(/* webpackChunkName: "academy" */ '@/views/Academy/lessons/methods/full-house/FullHouse.vue'),
    meta,
  },
  {
    path: '/de/faq',
    component: () => import(/* webpackChunkName: "faq" */ '@/views/Faq.vue'),
    meta,
  },
  {
    path: '/de/gewinner_innen',
    component: () => import(/* webpackChunkName: "winners" */ '@/views/Winners.vue'),
    meta,
  },
  {
    path: '/de/impressum',
    component: () => import(/* webpackChunkName: "legal" */ '@/views/Legal.vue'),
    meta,
  },
  {
    path: '/de/kontakt',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
    meta,
  },
  {
    path: '/de/geloeste-raetsel',
    component: () => import(/* webpackChunkName: "solvedPuzzles" */ '@/views/SolvedPuzzles.vue'),
    meta,
  },
  {
    path: '/de/gespeicherte-raetsel',
    component: () => import(/* webpackChunkName: "savedPuzzles" */ '@/views/SavedPuzzles.vue'),
    meta,
  },
  {
    path: '/de/datenschutzerklaerung',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/Terms.vue'),
    meta,
  },
  {
    path: '/de/profil',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    meta,
  },
  {
    path: '/de/spielanleitung',
    component: () => import(/* webpackChunkName: "instructions" */ '@/views/Instructions.vue'),
    meta,
  },
  {
    path: '/de/sudoku-des-tages',
    component: () => import(/* webpackChunkName: "dailySudoku" */ '@/views/DailySudoku.vue'),
    meta,
  },
  {
    path: '/de/synapsen',
    alias: [
      '/de/synapsen/2024/03/26/entdecke-wie-sudoku-dich-geistig-fit-haelt',
      '/de/synapsen/2024/04/02/willkommen-in-der-sudoku-knacker-akademie',
      '/de/synapsen/2024/04/11/ein-offenes-wort-warum-werbung-auf-sudoku-knacker-unverzichtbar-ist',
      '/de/synapsen/2024/04/21/die-faszinierende-geschichte-des-sudoku-ursprung-und-globale-verbreitung',
      '/de/synapsen/2024/05/05/warum-das-taegliche-loesen-von-sudoku-raetseln-zu-deinem-ritual-werden-sollte',
      '/de/synapsen/2024/05/16/sudoku-als-bruecke-zwischen-den-generationen',
      '/de/synapsen/2024/05/26/entdecke-das-geheimnis-des-phistomefel-rings',
      '/de/synapsen/2024/06/08/das-dutch-miracle-ein-meisterwerk-des-sudoku-designs',
      '/de/synapsen/2024/06/23/die-wissenschaft-hinter-sudoku-wie-das-raetsel-dein-gehirn-trainiert',
      '/de/synapsen/2024/07/21/der-beruhigende-effekt-von-sudoku-ein-konterpart-zum-hektischen-alltag',
    ],
    component: () => import(/* webpackChunkName: "synapses" */ '@/views/Synapses.vue'),
    meta,
    children: [
      {
        path: '/de/synapsen/2024/03/26/entdecke-wie-sudoku-dich-geistig-fit-haelt',
      },
      {
        path: '/de/synapsen/2024/04/02/willkommen-in-der-sudoku-knacker-akademie',
      },
      {
        path: '/de/synapsen/2024/04/11/ein-offenes-wort-warum-werbung-auf-sudoku-knacker-unverzichtbar-ist',
      },
      {
        path: '/de/synapsen/2024/04/21/die-faszinierende-geschichte-des-sudoku-ursprung-und-globale-verbreitung',
      },
      {
        path: '/de/synapsen/2024/05/05/warum-das-taegliche-loesen-von-sudoku-raetseln-zu-deinem-ritual-werden-sollte',
      },
      {
        path: '/de/synapsen/2024/05/26/entdecke-das-geheimnis-des-phistomefel-rings',
      },
      {
        path: '/de/synapsen/2024/06/08/das-dutch-miracle-ein-meisterwerk-des-sudoku-designs',
      },
      {
        path: '/de/synapsen/2024/06/23/die-wissenschaft-hinter-sudoku-wie-das-raetsel-dein-gehirn-trainiert',
      },
      {
        path: '/de/synapsen/2024/07/21/der-beruhigende-effekt-von-sudoku-ein-konterpart-zum-hektischen-alltag',
      },
    ],
  },
  {
    path: '/de/unterstuetzer_innen',
    component: () => import(/* webpackChunkName: "supporter" */ '@/views/Supporter.vue'),
    meta,
  },
  {
    path: '/de/urheberrechte',
    component: () => import(/* webpackChunkName: "copyright" */ '@/views/Copyright.vue'),
    meta,
  },
  {
    path: '/de/versionshinweise',
    alias: [
      '/de/versionshinweise/2021-1',
      '/de/versionshinweise/2021-2',
      '/de/versionshinweise/2023-0',
      '/de/versionshinweise/2024-0',
      '/de/versionshinweise/2024-1',
      '/de/versionshinweise/2024-2',
      '/de/versionshinweise/2024-3',
    ],
    component: () => import(/* webpackChunkName: "changelog" */ '@/views/ReleaseNotes.vue'),
    meta,
    children: [
      {
        path: 'de/versionshinweise',
      },
      {
        path: 'de/versionshinweise/2021-1',
      },
      {
        path: 'de/versionshinweise/2021-2',
      },
      {
        path: 'de/versionshinweise/2023-0',
      },
      {
        path: 'de/versionshinweise/2024-0',
      },
      {
        path: 'de/versionshinweise/2024-1',
      },
      {
        path: 'de/versionshinweise/2024-2',
      },
      {
        path: 'de/versionshinweise/2024-3',
      },
    ],
  },

  {
    path: '*',
    component: () => import(/* webpackChunkName: "copyright" */ '@/views/StatusCode404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
