<template>
  <div>
    <div class="text-right">
      <logout />
    </div>
    <div class="text-center">
      <v-avatar v-if="profile.userAvatar" size="75" class="mb-3">
        <img class="avatar--image" :src="profile.userAvatar" :alt="$t('components.app.login.img_profile')" />
      </v-avatar>
      <fa-icon v-else :class="$theme.app.navigation.buttons.open.text" class="mb-4" size="5x" :icon="['fal', 'user-circle']"></fa-icon>
      <p class="login__name mb-1">{{ userName }}</p>
      <p class="grey--text text--darken-1">{{ profile.userInfo }}</p>
    </div>
  </div>
</template>

<script>
import Logout from '@/components/Logout.vue'
import { mapGetters } from 'vuex'
import { theme } from '@/mixins'

export default {
  name: 'LoginAuthenticated',
  components: { Logout },
  mixins: [theme],
  computed: {
    ...mapGetters(['profile', 'user']),
    profile() {
      return this.$store.getters.profile
    },
    userName() {
      return this.profile.userName === '' ? this.$t('components.app.login.p_nomen_nescio') : this.profile.userName
    },
  },
}
</script>

<style scoped></style>
