import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

import comAlert from '@/store/modules/comAlert'
import cookieConsent from '@/store/modules/cookieConsent'
import counter from '@/store/modules/counter'
import dailySudoku from '@/store/modules/dailySudoku'
import ezoic from '@/store/modules/ezoic'
import highlightMode from '@/store/modules/invertMode'
import invertMode from '@/store/modules/highlightMode'
import loader from '@/store/modules/loader'
import navigation from '@/store/modules/navigation'
import payment from '@/store/modules/payment'
import speedMode from '@/store/modules/speedMode'
import sudoku from '@/store/modules/sudoku'
import suncalc from '@/store/modules/suncalc'
import user from '@/store/modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    comAlert,
    cookieConsent,
    counter,
    dailySudoku,
    ezoic,
    highlightMode,
    invertMode,
    loader,
    navigation,
    payment,
    speedMode,
    sudoku,
    suncalc,
    user,
  },
  //  plugins: [createPersistedState()],
})
