export default {
  state: {
    navigation: {
      show: false,
      activeElementId: 0,
    },
  },
  getters: {
    navigation(state) {
      return state.navigation
    },
  },
  mutations: {
    SET_SHOW(state, val) {
      state.navigation.show = val
    },
    SET_ACTIVE_ELEMENT_ID(state, val) {
      state.navigation.activeElementId = val
    },
  },
  actions: {
    hideNavigation({ commit }) {
      commit('SET_SHOW', false)
    },
    showNavigation({ commit }) {
      commit('SET_SHOW', true)
    },
    setActiveElementId({ commit }, val) {
      commit('SET_ACTIVE_ELEMENT_ID', val)
    },
  },
}
